import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner';
import { labels } from "../../constant/lable";
import { Web3Context } from "../../web3/contexts/web3Context";
const Video = ({ date }) => {

    const [countDownDate, setCountDownDate] = useState(null);
    const [countDown, setCountDown] = useState(0);
    const [startMint, setStartMint] = useState(false)
    const [mintStatus, setMintStatus] = useState()
    const { networkDetails, loading } = useContext(Web3Context);


    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSeconds] = useState(0);

    useEffect(() => {

        if (networkDetails && date.contractDeploymentDate !== undefined) {

            let startDate = new Date(date?.contractDeploymentDate).getTime() * 1000; // public mint start time
            let endDate = new Date(date?.contractDeploymentEndDate).getTime() * 1000; // public mint end time
            setCountDownDate(startDate)

            let isWhitelistuser = networkDetails.isWhitelist;
            let isAdmin = networkDetails.isAdmin;

            let currDate = new Date().getTime();
            let whitelistStart = (startDate - (Number(process.env.REACT_APP_PRESALESTART) * 60 * 1000)); // whitelist mint start time
            let whitelistEnd = (startDate - (Number(process.env.REACT_APP_BANNEDTIME) * 60 * 1000)); // banned start time
            if (currDate > whitelistStart && currDate < whitelistEnd && (isWhitelistuser || isAdmin)) {
                setMintStatus("whitelist")
                setStartMint(true)
            } else if (currDate > startDate && currDate < endDate) {
                setMintStatus("public")
                setStartMint(true)
            } else if (currDate > whitelistEnd && currDate < startDate) {
                setStartMint(false)
            } else {
                setStartMint(false)
            }
        }
    }, [date, countDown, networkDetails]) // eslint-disable-line

    useEffect(() => { // fetch & set time left
        if (!isNaN(countDownDate) && countDownDate !== null) {
            const interval = setInterval(() => {
                let newtime = new Date(countDownDate).getTime() - new Date().getTime()
                setCountDown(newtime);
                let { days, hours, minutes, seconds } = getReturnValues(newtime);
                setDay(days)
                setHour(hours)
                setMinute(minutes)
                setSeconds(seconds)

            }, 1000);
            return () => clearInterval(interval);

        }

    }, [countDownDate]);

    const getReturnValues = (countDown) => {
        let startMints = false;
        if (!isNaN(countDown) && countDown !== null) {
            // calculate time left
            const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

            if (days < 0 && hours < 0 && minutes < 0) {
                startMints = true;
            }
            if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
                return { days: days, hours: hours, minutes: minutes, seconds: seconds, startMint: startMints };
            } else {
                return { days: 0, hours: 0, minutes: 0, seconds: 0, startMint: startMints };
            }
        } else {
            return { days: 0, hours: 0, minutes: 0, seconds: 0, startMint: startMints };
        }
    };

    const homeMintSeo = (() => {
        if (window?.dataLayer) {
            window.dataLayer.push(labels.homeMint)
        }
    })

    return <>
        <section className="h-100 position-relative overflow-hidden d-flex flex-column justify-content-end py-5">
            {loading &&
                <div className="customLoader">
                    <TailSpin
                        type="Rings"
                        color="#fff"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className="video-background">
                <video autoPlay playsInline muted loop preload="auto">
                    <source src="video/teaser.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="container-fluid position-relative py-5">
                <div className="row align-items-end">
                    <div className="col-sm-6 text-light">
                        <h1 className="mb-1" data-aos="fade-up" data-aos-delay="0">The B&O DNA</h1>
                        {(day > 0 || hour > 0 || minute > 0 || second > 0) && (<>
                            <p className="lead fw-normal mb-5" data-aos="fade-up" data-aos-delay="100">Launch of public mint</p>
                            {countDownDate !== null && (<>
                                <div className="mb-4 countdown">
                                    <span>{day}D</span>
                                    <span>{hour}H</span>
                                    <span>{minute}M</span>
                                    <span>{second}S</span>
                                </div>
                            </>)
                            }
                        </>)}
                        {networkDetails?.connected && startMint &&
                            <a href="/mint" onClick={homeMintSeo} className="btn btn-primary">{mintStatus === "whitelist" ? "Pre-mint" : "Mint"}</a>
                        }

                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Video;