import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Artgallery from "../Artgallery";
import Artist from "../Artist";
import Asseenon from "../Asseenon";
import Faq from "../Faq";
import Musicians from "../Musicians";
import Projects from "../Projects";
import Roadmap from "../Roadmap";
import Vials from "../Vials";
import VideoSection2 from "../VideoSection2";
import Video from "../Video";
import PhysicalProduct from "../PhysicalProduct";

const Main = ({ date }) => {
  const navigate = useNavigate();

  useEffect(() => {
    // restrict routes
    let path = window.location.pathname;
    if (
      date &&
      (path === "/redeem" ||
        path === "/claim" ||
        path === "/merge" ||
        path === "/musicVialClaim" ||
        path === "mergeAndReveal") &&
      (date.isRedeemShow === false ||
        date.isClaimShow === false ||
        date.isMergeShow === false ||
        date.isMusicVialClaimShow ||
        date.isMergeRevealShow)
    ) {
      navigate("/");
    }
  }, [date]); // eslint-disable-line

  return (
    <>
      <Video date={date} />
      <PhysicalProduct />
      <VideoSection2 />
      <Projects />
      <Artgallery />
      <Vials />
      <Roadmap />
      <Artist />
      <Musicians />

      <Faq />
      <Asseenon />
    </>
  );
};

export default Main;
