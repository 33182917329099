import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner';
import * as Yup from 'yup';
import { labels } from "../../constant/lable";
import { mergeReveal } from '../../services/azure';
import { artVialMerge } from '../../services/Transaction';
import { enviornment } from "../../web3/constants/constants";
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';
import Success from '../Modal/success';
import Conformation from '../Modal/mergeConformation';
import { toast } from 'react-toastify';


const Merge = (props) => {
    const { date } = props
    const [getPhase0Instance, setPhase0Instance] = useState()
    const [getPhaseOneInstance, setPhaseOneInstance] = useState()
    const [getPhaseTwoInstance, setPhaseTwoInstance] = useState()
    const [nftOfGenesis, setNftOfGenesis] = useState([])
    const [nftOfArtVial, setNftOfArtVial] = useState([])
    const [countDownDate, setCountDownDate] = useState(null);
    const [countDown, setCountDown] = useState(0);
    const [showMerge, setShowMerge] = useState(false)
    const [show, setShow] = useState(false);
    const [mergeVal, setMergeVal] = useState()
    const [conformationShow, setConformationShow] = useState(false)
    const { networkDetails, setLoading, loading } = useContext(Web3Context);

    const setApprovalForAll = async (instance, operator) => {
        let result
        if (instance) {
            await poolMethods.setApprovalForAll(instance, networkDetails.address, operator).then(res => {
                if (res) {
                    result = true
                }
            }).catch(err => {
                result = false
            })
        }
        return result
    }

    const getMergeNftList = async () => {
        if (networkDetails && getPhase0Instance && getPhaseOneInstance) {
            //setLoading(true)
            const genesisNftList = await poolMethods.nftOfUser(getPhase0Instance, networkDetails.address)
            setNftOfGenesis(genesisNftList)
            const aetVialNftList = await poolMethods.nftOfUser(getPhaseOneInstance, networkDetails.address)
            setNftOfArtVial(aetVialNftList)
            //setLoading(false)
        }
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleCloseConformation = () => {
        formik.resetForm()
        setConformationShow(false)
    }

    const mergeSeo = (() => {
        if (window?.dataLayer) {
            window.dataLayer.push(labels.mergeNow)
        }
    })

    const validateSchema = Yup.object().shape({
        genesisNft: Yup.number()
            .required(labels.merge.validationError),
        artVial: Yup.number()
            .required(labels.merge.validationError)
    })
    // ** Form Value
    const initialValues = {
        genesisNft: "",
        artVial: ""
    }

    // ** Formic Form
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {
              setConformationShow(true)
            setMergeVal(values)
            // try {
            //     if (networkDetails && getPhase0Instance && getPhaseOneInstance && values) {
            //         mergeSeo()
            //         let envData = enviornment
            //         const networkId = await networkDetails.web3.eth.net.getId();
            //         let isApprove0;
            //         let isApproveOne;
            //         let phase0 = await poolMethods.isApprovedForAll(getPhase0Instance, networkDetails.address, envData[networkId].PhaseTwoAddress)
            //         if (phase0 === false) {
            //             setLoading(true)
            //             let set0 = await setApprovalForAll(getPhase0Instance, envData[networkId].PhaseTwoAddress)
            //             setLoading(false)
            //             isApprove0 = set0
            //         } else {
            //             isApprove0 = phase0
            //         }
            //         let phaseOne = await poolMethods.isApprovedForAll(getPhaseOneInstance, networkDetails.address, envData[networkId].PhaseTwoAddress)
            //         if (phaseOne === false) {
            //             setLoading(true)
            //             let setOne = await setApprovalForAll(getPhaseOneInstance, envData[networkId].PhaseTwoAddress)
            //             setLoading(false)
            //             isApproveOne = setOne
            //         } else {
            //             isApproveOne = phaseOne
            //         }

            //         if (isApprove0 === true && isApproveOne === true) {
            //             setLoading(true)
            //             await poolMethods.merge(getPhaseTwoInstance, networkDetails.address, values.genesisNft, values.artVial).then(async (result) => {
            //                 if (result) {
            //                     let mergeTokenId
            //                     result.events.Transfer.map((res) => {
            //                         if (res.returnValues.from === process.env.REACT_APP_REDEEM) {
            //                             mergeTokenId = res.returnValues.tokenId
            //                         }
            //                         return mergeTokenId
            //                     })
            //                     let obj = {
            //                         firstFileToken: values.genesisNft.toString(),
            //                         secondFileToken: values.artVial.toString(),
            //                         desinationFileName: mergeTokenId,
            //                         walletAddress: networkDetails.address
            //                     }

            //                     await artVialMerge(obj)
            //                     await mergeReveal(obj).then(res => {
            //                         if (res) {
            //                             setShow(true)
            //                         }
            //                     }).catch(err => {
            //                         // console.log("merge ====", err)
            //                     })
            //                     getMergeNftList()
            //                     setLoading(false)
            //                     resetForm()
            //                 } else {
            //                     setLoading(false)
            //                 }
            //             }).catch(error => {
            //                 setLoading(false)
            //                 console.log("error", error)
            //             })
            //         }
            //     }
            // } catch (error) {
            //     console.log("error =", error)
            // }
        },
    });


    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instancePhase0 = await poolMethods.getPhase0Instance(networkDetails.web3)
                if (instancePhase0) {
                    setPhase0Instance(instancePhase0)
                }

                let instancePhaseOne = await poolMethods.getPhaseOneInstance(networkDetails.web3)
                if (instancePhaseOne) {
                    setPhaseOneInstance(instancePhaseOne)
                }

                let instancePhaseTwo = await poolMethods.getPhaseTwoInstance(networkDetails.web3)
                if (instancePhaseTwo) {
                    setPhaseTwoInstance(instancePhaseTwo)
                }
            }
        })();
    }, [networkDetails])

    useEffect(() => {  // Fetch and set Merge token list
        if (networkDetails && getPhase0Instance && getPhaseOneInstance) {
            getMergeNftList()
        }
    }, [getPhase0Instance, getPhaseOneInstance]) // eslint-disable-line

    useEffect(() => { // set Merge start time
        if (networkDetails.connected && date.mergeDate !== undefined) {
            let mergeDate = new Date(date?.mergeDate).getTime() * 1000;
            setCountDownDate(mergeDate)
        }
    }, [date, networkDetails, countDown]) // eslint-disable-line

    useEffect(() => {
        if (networkDetails.connected && date?.mergeDate !== undefined) {
            const interval = setInterval(async () => {
                let newtime = new Date(countDownDate).getTime()
                let currDate = new Date().getTime()
                if (currDate > newtime) {
                    setShowMerge(true)
                } else {
                    setShowMerge(false)
                }
                setCountDown(newtime);
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [countDownDate]); // eslint-disable-line
   
    const onHandleConformation = async () => {
        try {
                if (networkDetails && getPhase0Instance && getPhaseOneInstance && mergeVal) {
                    setLoading(true)
                    mergeSeo()
                    let envData = enviornment
                    const networkId = await networkDetails.web3.eth.net.getId();
                    let isApprove0;
                    let isApproveOne;
                    let phase0 = await poolMethods.isApprovedForAll(getPhase0Instance, networkDetails.address, envData[networkId].PhaseTwoAddress)
                    if (phase0 === false) {
                        setLoading(true)
                        let set0 = await setApprovalForAll(getPhase0Instance, envData[networkId].PhaseTwoAddress)
                        setLoading(false)
                        isApprove0 = set0
                    } else {
                        isApprove0 = phase0
                    }
                    let phaseOne = await poolMethods.isApprovedForAll(getPhaseOneInstance, networkDetails.address, envData[networkId].PhaseTwoAddress)
                    if (phaseOne === false) {
                        setLoading(true)
                        let setOne = await setApprovalForAll(getPhaseOneInstance, envData[networkId].PhaseTwoAddress)
                        setLoading(false)
                        isApproveOne = setOne
                    } else {
                        isApproveOne = phaseOne
                    }

                    if (isApprove0 === true && isApproveOne === true) {
                        setLoading(true)
                        await poolMethods.merge(getPhaseTwoInstance, networkDetails.address, mergeVal.genesisNft, mergeVal.artVial).then(async (result) => {
                            if (result) {
                                let mergeTokenId
                                result.events.Transfer.map((res) => {
                                    if (res.returnValues.from === process.env.REACT_APP_REDEEM) {
                                        mergeTokenId = res.returnValues.tokenId
                                    }
                                    return mergeTokenId
                                })
                                let obj = {
                                    firstFileToken: mergeVal.genesisNft.toString(),
                                    secondFileToken: mergeVal.artVial.toString(),
                                    desinationFileName: mergeTokenId,
                                    walletAddress: networkDetails.address
                                }

                                await artVialMerge(obj)
                                await mergeReveal(obj).then(res => {
                                    if (res) {
                                        setShow(true)
                                    }
                                }).catch(err => {
                                    // console.log("merge ====", err)
                                })
                                getMergeNftList()
                                setLoading(false)
                                setConformationShow(false)
                                setMergeVal()
                                formik.resetForm()
                            } else {
                                setLoading(false)
                            }
                        }).catch(error => {
                            toast.error(error.message)
                            setLoading(false)
                            setConformationShow(false)
                            console.log("error", error)
                        })
                    }
                }
            } catch (error) {
                console.log("error =", error)
                setConformationShow(false)
                setMergeVal()
                formik.resetForm()
                toast.error("There is some issue with merge.")
            }
    }


    return <>
        < Success successMsg={labels.merge.mergeSuccess} opensea={labels.merge.opensea} show={show} handleClose={handleClose} />
        < Conformation alredyMerged={labels.merge.alredyMerged} reversable={labels.merge.reversable} conformation={labels.merge.conformation} remember={labels.merge.remember} show={conformationShow} handleClose={handleCloseConformation} 
          onHandleConformation={onHandleConformation}
        />
        <section className="h-100 bg-black d-flex flex-column justify-content-center py-5">
            {loading &&
                <div className="customLoader">
                    <TailSpin
                        type="Rings"
                        color="#fff"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="border py-5 p-4 p-sm-5 text-white">
                            <div className="text-center mb-5">
                                <h2>The Merge</h2>
                            </div>
                            <div className="row">
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                        <select className="form-select unstyled"
                                            value={formik.values.genesisNft}
                                            onChange={(e) => {
                                                let val = Number(e.target.value)
                                                formik.setFieldValue('genesisNft', val)
                                            }}
                                        >
                                            <option value="" className="option" disabled>Select B&0 Genesis NFT</option>
                                            {nftOfGenesis.length > 0 && nftOfGenesis.map((list, idx) => {
                                                return <option className="option" value={list} key={idx}>{list}</option>
                                            })}
                                        </select>
                                    </div>
                                    {formik.touched.genesisNft && formik.errors.genesisNft ? (
                                        <p className='validationMsg'>
                                            {formik.errors.genesisNft}
                                        </p>
                                    ) : null}
                                </div>
                                <div className="col-sm-2 d-flex align-items-center justify-content-center my-5 my-sm-0">
                                    <svg width="70" height="70" viewBox="0 0 71 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <line y1="35.2494" x2="71" y2="35.2494" stroke="#FCFAEE" strokeWidth="1.50126" />
                                        <line x1="34.2494" y1="71.5" x2="34.2494" y2="0.5" stroke="#FCFAEE" strokeWidth="1.50126" />
                                    </svg>
                                </div>
                                <div className="col-sm-5">
                                    <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                        <select className="form-select unstyled"
                                            value={formik.values.artVial}
                                            onChange={(e) => {
                                                let val = Number(e.target.value)
                                                formik.setFieldValue('artVial', val)
                                            }}
                                        >
                                            <option value="" className="option" disabled>Select B&0 Art Vial</option>
                                            {nftOfArtVial.length > 0 && nftOfArtVial.map((list, idx) => {
                                                return <option className="option" value={list} key={idx}>{list}</option>
                                            })}
                                        </select>
                                    </div>
                                    {formik.touched.artVial && formik.errors.artVial ? (
                                        <p className='validationMsg'>
                                            {formik.errors.artVial}
                                        </p>
                                    ) : null}
                                    {showMerge === true ?
                                        <button className="btn btn-light btn-lg w-100 mt-5" type="button"
                                            // data-start-loader
                                            onClick={formik.handleSubmit}
                                        >
                                            Merge NFTs
                                        </button> : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="loader">
            <div className="loader-progress"></div>
            <div className="loader-counter">0</div>
        </div>
    </>
}

export default Merge;