import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { labels } from '../../constant/lable';
import { moveAzureFiles } from '../../services/azure';
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';
import Success from '../Modal/success';


const MusicVialClaim = (props) => {
    const { date } = props
    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [getPhaseThreeInstance, setPhaseThreeInstance] = useState()
    const [getPhaseTwoInstance, setPhaseTwoInstance] = useState()
    const [getMusicVialClaimList, setMusicVialClaimList] = useState([])
    const [countDownDate, setCountDownDate] = useState(null);
    const [countDown, setCountDown] = useState(0);
    const [showClaim, setShowClaim] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instanceTwo = await poolMethods.getPhaseTwoInstance(networkDetails.web3)
                if (instanceTwo) {
                    setPhaseTwoInstance(instanceTwo)
                }

                let instancePhase3 = await poolMethods.getPhaseThreeInstance(networkDetails.web3)
                if (instancePhase3) {
                    setPhaseThreeInstance(instancePhase3)
                }
            }
        })();
    }, [networkDetails])

    useEffect(() => { // set merge & revel start time
        if (networkDetails.connected && date?.musicVialClaimDate !== undefined) {
            let musicVialClaimDate = new Date(date?.musicVialClaimDate).getTime() * 1000;
            setCountDownDate(musicVialClaimDate)
        }
    }, [date, networkDetails, countDown]) // eslint-disable-line

    useEffect(() => {
        if (networkDetails.connected) {
            const interval = setInterval(async () => {
                let newtime = new Date(countDownDate).getTime()
                let currDate = new Date().getTime()
                if (currDate > newtime) {
                    setShowClaim(true)
                } else {
                    setShowClaim(false)
                }
                setCountDown(newtime);
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [countDownDate]); // eslint-disable-line

    const claimList = async () => { // fetch & set claim list
        if (getPhaseTwoInstance && getPhaseThreeInstance && networkDetails) {
            const list = await poolMethods.nftOfUser(getPhaseTwoInstance, networkDetails.address)
            formik.resetForm()
            if (list && list.length > 0) {
                let nftList = []
                for (let tokenId of list) {
                    let status = await poolMethods.alreadyClaimed(getPhaseThreeInstance, networkDetails.address, tokenId)
                    if (status === false) {
                        nftList.push(tokenId)
                    }
                }
                setMusicVialClaimList(nftList)
            } else {
                setMusicVialClaimList(list)
            }
        }
    }

    const validateSchema = Yup.object().shape({
        musicVialClaimDate: Yup.number()
            .required(labels.musicVialClaim.validationError)
    })
    // ** Form Value
    const initialValues = {
        musicVialClaimDate: "",
    }

    // ** Formic Form
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {
            if (networkDetails && getPhaseThreeInstance && values.musicVialClaimDate >= 0) {
                setLoading(true)
                await poolMethods.claim(getPhaseThreeInstance, networkDetails.address, values.musicVialClaimDate).then(async (data) => {
                    if (data) {
                        let tokenId = data.events.Transfer.returnValues.tokenId
                        const obj = {
                            fileName: tokenId.toString(),
                            phase: "musicVialClaim"
                        }
                        await moveAzureFiles(obj).then((result) => {
                            if (result) {
                                claimList()
                                toast.success(labels.musicVialClaim.success)
                                setShow(true)
                                resetForm()
                                setLoading(false)
                            }
                        }).catch(errors => {
                            claimList()
                            resetForm()
                            setLoading(false)
                        })
                    }
                }).catch((err) => {
                    claimList()
                    setLoading(false)
                    resetForm()
                    console.log("err", err)
                })
            }
        },
    });


    useEffect(() => {
        if (getPhaseTwoInstance && getPhaseThreeInstance && networkDetails) {
            claimList()
        }
    }, [getPhaseTwoInstance, getPhaseThreeInstance])// eslint-disable-line

    return <>
        < Success successMsg={labels.musicVialClaim.musicVialClaimSuccess} opensea={labels.musicVialClaim.opensea} show={show} handleClose={handleClose} />
        <section>

            {loading &&
                <div className="customLoader">
                    <TailSpin  // spin loader
                        type="Rings"
                        color="#fff"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div>
                        <div className="border py-5 p-4 p-sm-5 text-white cheight">
                            <div className="text-center mb-5">
                                <h2>Music Vial Claim NFT</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                <select className="form-select unstyled"
                                    value={formik.values.musicVialClaimDate}
                                    onChange={(e) => {
                                        if (e.target.value !== "Select Token ID") {
                                            let val = Number(e.target.value)
                                            formik.setFieldValue('musicVialClaimDate', val)
                                        }
                                    }}
                                >
                                    <option value={""} disabled className="option">Select Token ID</option>
                                    {getMusicVialClaimList.length > 0 && getMusicVialClaimList.map((data, idx) => {
                                        return <option value={data} className="option" key={idx}>{data}</option>
                                    })}
                                </select>
                            </div>
                            {formik.touched.musicVialClaimDate && formik.errors.musicVialClaimDate ? (
                                <p className='validationMsg'>
                                    {formik.errors.musicVialClaimDate}
                                </p>
                            ) :
                                <p className='validationMsg mb-10'></p>}

                            {showClaim !== true ?
                                <button className="btn btn-light btn-lg w-100 mt-5" type="submit" onClick={formik.handleSubmit}
                                // data-start-loader
                                >
                                    Claim now
                                </button>
                                : ""}
                        </div>
                        <div className="mt-3 text-white small text-center">
                            Each B&O DNA NFT is unique and created by B&O
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="loader">
            <div className="loader-progress"></div>
            <div className="loader-counter">0</div>
        </div>
    </>
}

export default MusicVialClaim;