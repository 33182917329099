import React from "react";

const Vials = () => {
    return <>
        <section id="vials" className="position-relative h-60 py-sm-5 bg-white d-flex flex-column justify-content-center">
            <div className="background-image">
                <img src="/img/artvial.jpg" className="right-mobile" alt="" />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-4 py-5 text-dark text-sm-light">
                        <div className="pe-5 pe-sm-0">
                            <p className="subtitle mb-2" data-aos="fade-up" data-aos-delay="0">Art vials</p>
                            <h2 className="mb-4" data-aos="fade-up" data-aos-delay="100">The DNA Fusion</h2>
                            <p className="mb-4" data-aos="fade-up" data-aos-delay="200">Following the mint of the DNA Collection, our NFT owners can claim an Art Vial and a Music Vial, giving them the ability to fuse the DNA of Bang & Olufsen with the DNAs of our collaborating artists and musicians, creating a new kind of collectible.</p>
                            <div className="d-flex gap-3" data-aos="fade-in" data-aos-delay="500">
                                {/* <a href="#artists" className="btn btn-primary" rel="noreferrer">
                                    The Artists
                                </a>
                                <a href="#music" className="btn btn-primary" rel="noreferrer">
                                    The Musicians
                                </a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 offset-sm-1">

                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Vials;