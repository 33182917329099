export const message = {
    web3: {
        nonEtheriyam: "Non-Ethereum browser detected. you should consider trying MetaMask",
        userRejected: "User rejected the request from Metamask.",
        blackListed: "Your address is blacklisted. Please contact admin for any queries.",
        connectError: "Connect with metamask to do minting.",
        disconnected: "Account disconneted.",
        successMessage: "Data load successfully.",
        networkError: "Network not supported, Please change to Mainnet",
        extensionError: "Metamask Extension Not Found ! Please Install Metamask to Connect",
        instanceError: "Issue with instance",
    }
}