import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { labels } from "../../constant/lable";
import { moveAzureFiles } from "../../services/azure";
import { Web3Context } from "../../web3/contexts/web3Context";
import { poolMethods } from "../../web3/functions/factory";
import Success from "../Modal/success";

const Claim = (props) => {
  const { date } = props;
  const { networkDetails, loading, setLoading } = useContext(Web3Context);
  const [getPhaseOneInstance, setPhaseOneInstance] = useState();
  const [getPhase0Instance, setPhase0Instance] = useState();
  const [getClaimList, setClaimList] = useState([]);
  const [countDownDate, setCountDownDate] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [showClaim, setShowClaim] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    (async () => {
      if (networkDetails && networkDetails.connected) {
        let instancePhaseOne = await poolMethods.getPhaseOneInstance(
          networkDetails.web3
        );
        if (instancePhaseOne) {
          setPhaseOneInstance(instancePhaseOne);
        }

        let instancePhase0 = await poolMethods.getPhase0Instance(
          networkDetails.web3
        );
        if (instancePhase0) {
          setPhase0Instance(instancePhase0);
        }
      }
    })();
  }, [networkDetails]);

  useEffect(() => {
    // set claim start time
    if (networkDetails.connected && date?.claimDate !== undefined) {
      let claimDate = new Date(date?.claimDate).getTime() * 1000;
      setCountDownDate(claimDate);
    }
  }, [date, networkDetails, countDown]); // eslint-disable-line

  useEffect(() => {
    if (networkDetails.connected && date?.claimDate !== undefined) {
      const interval = setInterval(async () => {
        let newtime = new Date(countDownDate).getTime();
        let currDate = new Date().getTime();
        if (currDate > newtime) {
          setShowClaim(true);
        } else {
          setShowClaim(false);
        }
        setCountDown(newtime);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDownDate]); // eslint-disable-line

  const claimList = async () => {
    // fetch & set claim list
    if (getPhase0Instance && networkDetails) {
      const list = await poolMethods.nftOfUser(
        getPhase0Instance,
        networkDetails.address
      );
      formik.resetForm();
      if (list.length > 0) {
        let nftList = [];
        for (let nftId of list) {
          let status = await poolMethods.alreadyClaimed(
            getPhaseOneInstance,
            networkDetails.address,
            nftId
          );
          if (status === false) {
            nftList.push(nftId);
          }
        }
        setClaimList(nftList);
      } else {
        setClaimList(list);
      }
    }
  };
  const claimSeo = () => {
    if (window?.dataLayer) {
      window.dataLayer.push(labels.claimAtrVialClaimNow);
    }
  };

  const validateSchema = Yup.object().shape({
    claim: Yup.number().required(labels.claim.validationError),
  });
  // ** Form Value
  const initialValues = {
    claim: "",
  };

  // ** Formic Form
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: async (values, { resetForm }) => {
      if (networkDetails && getPhaseOneInstance && values.claim >= 0) {
        claimSeo();
        setLoading(true);
        await poolMethods
          .claim(getPhaseOneInstance, networkDetails.address, values.claim)
          .then(async (data) => {
            if (data) {
              let tokenId = data.events.Transfer.returnValues.tokenId;
              let obj = {
                fileName: tokenId.toString(),
                phase: "claim",
              };
              await moveAzureFiles(obj)
                .then((result) => {
                  if (result) {
                    claimList();
                    setLoading(false);
                    toast.success(labels.claim.success);
                    setShow(true);
                    resetForm();
                    setLoading(false);
                  }
                })
                .catch((errors) => {
                  claimList();
                  resetForm();
                  setLoading(false);
                });
            } else {
              setLoading(false);
            }
          })
          .catch((err) => {
            claimList();
            setLoading(false);
            resetForm();
            console.log("err", err);
          });
      }
    },
  });

  useEffect(() => {
    if (getPhase0Instance && getPhaseOneInstance && networkDetails) {
      claimList();
    }
  }, [getPhase0Instance, getPhaseOneInstance]); // eslint-disable-line

  return (
    <>
      <Success
        successMsg={labels.artClaim.artClaimSuccess}
        opensea={labels.artClaim.opensea}
        show={show}
        handleClose={handleClose}
      />
      <section className="bg-black">
        {loading && (
          <div className="customLoader">
            <TailSpin // spin loader
              type="Rings"
              color="#fff"
              height={100}
              width={100}
            />
          </div>
        )}
        <div className="container-fluid">
          <div className="row align-items-center">
            <div>
              <div className="border py-5 p-4 p-sm-5 text-white cheight">
                <div className="text-center mb-5">
                  <h2>Claim Art Vial</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                  <select
                    className="form-select unstyled"
                    value={formik.values.claim}
                    onChange={(e) => {
                      if (e.target.value !== "Select Token ID") {
                        let val = Number(e.target.value);
                        formik.setFieldValue("claim", val);
                      }
                    }}
                  >
                    <option value={""} disabled className="option">
                      Select Token ID
                    </option>
                    {getClaimList.length > 0 &&
                      getClaimList.map((data, idx) => {
                        return (
                          <option value={data} className="option" key={idx}>
                            {data}
                          </option>
                        );
                      })}
                  </select>
                </div>
                {formik.touched.claim && formik.errors.claim ? (
                  <p className="validationMsg">{formik.errors.claim}</p>
                ) :
                  <p className='validationMsg mb-10'></p>
                }

                {showClaim !== true ? (
                  <button
                    className="btn btn-light btn-lg w-100 mt-5"
                    type="submit"
                    onClick={formik.handleSubmit}
                  // data-start-loader
                  >
                    Claim now
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="mt-3 text-white small text-center">
                Each B&O DNA NFT is unique and created by B&O
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Claim;
