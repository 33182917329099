import React from "react";
import { Modal } from "react-bootstrap";
// import blogo from '../../assets/image/B&Ologo.svg';
const Success = (props) => {
  let { successMsg, opensea, show, handleClose, note } = props;

  return (
    <>
      <Modal
        className="c-model"
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h2>Congratulations.</h2>
            <div className="mt-5">
              <p>{successMsg}</p>
              <p>{opensea}</p>
              <p className="text-primary">{note}</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Success;
