import React from "react";

const PhysicalProduct = () => {
  return (
    <>
      <section className="h-100 PhysicalProduct overflow-hidden">
        <div className="box px-3 px-md-0">
          <div className="row align-items-xl-end align-items-center h-100">
            <div className="col-xl-4 col-md-10 offset-md-2 offset-xl-8 rightSide">
              <small className="text-white text-uppercase">
                Custom DNA Speaker
              </small>
              <h2 className="text-white mt-sm-3 mt-2">
                From NFT to
                <br /> physical product
              </h2>
              <p className="my-sm-5 my-4 text-white">
                Transcend your DNA NFT into reality through the Beosound A9. 
                Combining the cutting-edge Bang & Olufsen icon with bespoke Web3 personalization.
              </p>
              <button
                className="btn btn-outline-xs-dark btn-outline-light active"
                data-content-toggle="true"
                onClick={() => window.location.href = "https://www.bang-olufsen.com/en/speakers/beosound-a9?variant=beosound-a9-dna-thomas-lin-pedersen"}
              >
                Shop Beosound A9 DNA Edition
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhysicalProduct;
