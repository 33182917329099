import { toast } from "react-toastify";
import Web3 from "web3";
import { getChainalysis, setChainalysis } from '../../services/Chainalysis';
import { enviornment } from '../constants/constants';
import { message } from "../constants/messages";
import { getChainData } from "../helpers/utils";

const loadWeb3 = async (setLoading) => {
  try {
    if (window.ethereum) {
      window.web3 = await new Web3(window.ethereum);
      await requestAccount();
    }
  } catch (err) {
    setLoading(false);
  }
};

async function requestAccount() {
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      });
      return accounts;
    } catch (error) {
      if (error.code === 4001) {
        alert('Error connecting');
      }
    }
  }
}



/**
* Purpose       : set whitelist method
* parameter     :
* Created date  : 20 Oct 2022
* Last updated  :
* Developer     : Nirav
*/
const setAdmin = async () => {
  const web3 = await window.web3;
  const accounts = await web3.eth.getAccounts();
  const networkId = await web3.eth.net.getId();
  let envData = enviornment;
  let Instance = await new web3.eth.Contract(envData[networkId].ERC20ABI, envData[networkId].ERC20Address);
  let owner = await Instance.methods.owner().call({ from: accounts[0] });
  let temp = owner.toLowerCase();
  let temp2 = accounts[0].toLowerCase();
  return ((temp2 && temp) && temp === temp2 ? true : false)
}


/**
* Purpose       : set whitelist method
* parameter     :
* Created date  : 20 Oct 2022
* Last updated  :
* Developer     : Nirav
*/
const whitelist = async () => {
  // isWhitelist
  const web3 = await window.web3;
  const accounts = await web3.eth.getAccounts();
  const networkId = await web3.eth.net.getId();
  let envData = enviornment;
  let Instance = await new web3.eth.Contract(envData[networkId].ERC20ABI, envData[networkId].ERC20Address);
  let whitelist = await Instance.methods.whitelistedAddresses(accounts[0]).call({ from: accounts[0] });
  return whitelist
}

/**
* Purpose       : Get total nfts
* parameter     :
* Created date  : 04 Nov 2022
* Last updated  :
* Developer     : Lalit
*/
const getTotalNfts = async () => {
  // isWhitelist
  const web3 = await window.web3;
  const accounts = await web3.eth.getAccounts();
  const networkId = await web3.eth.net.getId();
  let envData = enviornment;
  let Instance = await new web3.eth.Contract(envData[networkId].ERC20ABI, envData[networkId].ERC20Address);
  let totalSupplys = await Instance.methods.totalSupply().call({ from: accounts[0] });
  return totalSupplys
}


const loadBlockChainData = async (setNetworkDetails, networkDetails, setLoading, resetApp) => {
  try {
    const web3 = await window.web3;
    const wallet = "metamask";

    // listen the Chain ID
    const chainId = await window.ethereum.chainId;
    const chainData = chainId ? await getChainData(chainId) : null;
    const networkVersion = window.ethereum.networkVersion;
    // todo : here we have added static condition for the network as of now we consider for matic = REACT_APP_NETWORKID
    if (chainData && chainData.isChainValid && networkVersion === process.env.REACT_APP_NETWORKID && web3 && web3.eth) {
      // Load Account
      const accounts = await web3.eth.getAccounts();
      let balance = await web3.eth.getBalance(accounts[0]);
      balance = balance / 1e18;
      localStorage.setItem("injected", 'true');
      localStorage.setItem("wallet_name", "metamask");
      // listen the Network ID
      const networkId = await web3.eth.net.getId();


      // todo : here we are adding chainalysis call
      let isBlackListed = true;
      let setResult = await setChainalysis({ address: accounts[0] });
      if (setResult.code === 200) {
        let result = await getChainalysis(accounts[0]);

        if (result.code === 200) {
          if (result.data && (result.data.risk === "Severe" || result.data.risk === "High"))
            isBlackListed = false;
        } else {
          isBlackListed = true;
        }
      }

      let isAdm = await setAdmin()
      let isWt = await whitelist()
      let totalNfts = await getTotalNfts()

      if (isBlackListed === true) {
        await setNetworkDetails({
          ...networkDetails,
          address: accounts[0],
          web3: web3,
          isUpdated: true,
          connected: true,
          wallet: wallet,
          chainData: chainData,
          chainId: chainId,
          networkId: networkId,
          balance: balance,
          isAdmin: isAdm,
          isWhitelist: isWt,
          totalSupply: totalNfts
        });
      } else {
        toast.error(message.web3.blackListed);
        resetApp();
      }

      await setLoading(false);
    } else {
      await setNetworkDetails({
        isUpdated: true,
        ...networkDetails,
        address: "",
        web3: "",
        connected: false,
        wallet: "",
        chainData: "",
        chainId: "",
        networkId: "",
        balance: "",
        isAdmin: false,
      });
      toast.warning(message.web3.networkError);
      await setLoading(false);
    }

  } catch (err) {
    setLoading(false);
    toast.warning(message.web3.connectError);
  }
};

const listenAccountChange = async (setLoading, setNetworkDetails, resetApp) => {
  try {
    const web3 = window.web3;
    window.ethereum.on("accountsChanged", async () => {
      setLoading(true);
      const accounts = await web3.eth.getAccounts();
      const chainId = await window.ethereum.chainId;
      const chainData = chainId ? await getChainData(chainId) : null;
      const networkId = await web3.eth.net.getId();
      if (accounts.length !== 0) {
        let balance = await web3.eth.getBalance(accounts[0])
        balance = balance / 1e18
        localStorage.setItem("injected", 'true');
        localStorage.setItem("wallet_name", "metamask");
        let isAdm = await setAdmin()
        let isWt = await whitelist()
        let totalNfts = await getTotalNfts()

        let isBlackListed = true;
        let setResult = await setChainalysis({ address: accounts[0] });
        if (setResult.code === 200) {
          let result = await getChainalysis(accounts[0]);
          if (result.data === 200 && (result.data.risk === "Severe" || result.data.risk === "High")) {
            isBlackListed = false;
          } else {
            isBlackListed = true;
          }
        }
        if (isBlackListed === true) {
          await setNetworkDetails((prevState) => ({
            ...prevState,
            address: accounts[0],
            balance: balance,
            chainId: chainId,
            networkId: networkId,
            chainData: chainData,
            connected: true,
            web3: web3,
            isAdmin: isAdm,
            isWhitelist: isWt,
            totalSupply: totalNfts
          }));
          setLoading(false);
          toast.success(message.web3.successMessage)
        } else {

          toast.error(message.web3.blackListed);
          resetApp();
        }
      } else {
        setLoading(false);
        localStorage.clear()
        resetApp();
        toast.warning(message.web3.disconnected)
      }
    });
  } catch (err) {
    setLoading(false);
  }
};

const listenNetworkChange = async (setNetworkDetails, setLoading, resetApp) => {
  try {
    const web3 = window.web3;
    window.ethereum.on("chainChanged", async () => {
      const chainId = await window.ethereum.chainId;
      const chainData = chainId ? await getChainData(chainId) : null;
      const networkId = await web3.eth.net.getId();
      const accounts = await web3.eth.getAccounts();
      let balance = await web3.eth.getBalance(accounts[0])
      balance = balance / 1e18

      const networkVersion = window.ethereum.networkVersion;
      // todo : here we have added static condition for the network as of now we consider for matic = 8001
      if (chainData && chainData.isChainValid && networkVersion === process.env.REACT_APP_NETWORKID) {
        await setNetworkDetails((prevState) => ({
          ...prevState,
          chainId: chainId,
          networkId: networkId,
          chainData: chainData,
          balance: balance,
        }));

        setLoading(false);
      } else {
        resetApp();
        toast.error(message.web3.networkError);
        setLoading(false);
      }
    });
  } catch (err) {
    setLoading(false);
  }
};

export {
  loadWeb3,
  loadBlockChainData,
  listenAccountChange,
  listenNetworkChange,
};

