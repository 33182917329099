const supportedChains = [
  {
    name: "bsc-mainnet",
    chain_id: "0x38",
    network_id: 56,
  },
  {
    name: "bsc-testnet",
    chain_id: "0x61",
    network_id: 97,
  },
  {
    name: "ETH-mainnet",
    chain_id: "0x1",
    network_id: 1,
  },
  {
    name: "ropsten-testnet",
    chain_id: "0x3",
    network_id: 3,
  },
  {
    name: "Rinkeby-testnet",
    chain_id: "0x4",
    network_id: 4,
  },
  {
    name: "Goerli-testnet",
    chain_id: "0x5",
    network_id: 5,
  },
  {
    name: "Kovan-testnet",
    chain_id: "0x2a",
    network_id: 42,
  },
  {
    name: "Harmony-Testnet-RPC",
    chain_id: "0x6357d2e0",
    network_id: 1666700000,
  },
  {
    name: "Matic-Testnet",
    chain_id: "0x13881",
    network_id: 80001,
  },
];

export default supportedChains;
