import React, { useRef, useState } from "react";
const VideoSection2 = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);

  let vidRef = useRef();
  return (
    <>
      <section
        className="h-100 position-relative overflow-hidden d-flex flex-column justify-content-end py-5"
        onMouseEnter={() => {
          setShowControls(true);
        }}
        onMouseLeave={() => {
          setShowControls(false);
        }}
      >
        <div className="video-background">
          {showControls && (
            <div
              className="btn-player-center"
              style={{
                mixBlendMode: "exclusion",
                boxShadow: "10px 5px 5px black",
              }}
            >
              {
                <img
                  src={isPlaying ? "/img/pause.svg" : "/img/play.svg"}
                  width="100px"
                  height="100px"
                  alt="play"
                  onClick={() => {
                    setIsPlaying(!isPlaying);
                    isPlaying ? vidRef.pause() : vidRef.play();
                  }}
                />
              }
            </div>
          )}
          <video
            autoPlay
            playsInline
            muted
            loop
            preload="auto"
            ref={(el) => (vidRef = el)}
          >
            <source src="video/journey.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="container-fluid position-relative py-5">
          <div className="row align-items-end">
            <div className="col-sm-6 text-light">
              {/* <h1 className="mb-1" data-aos="fade-up" data-aos-delay="0">The B&O DNA</h1> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoSection2;
