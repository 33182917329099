import React from "react";


// const Images = ({ index }) => (
//     <>
//         <span className="item">
//             <img src="/img/1.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/2.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/8.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/5.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/4.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/6.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/7.jpg" alt="" />
//         </span>
//         <span className="item">
//             <img src="/img/3.jpg" alt="" />
//         </span>
//     </>

// )

const Artgallery = () => {

    // var settings = {
    //     infinite: true,
    //     autoplay: true,
    //     autoplaySpeed: 0,
    //     speed: 8000,
    //     cssEase: 'linear',
    //     arrows: false,
    //     pauseOnHover: false,
    //     swipe: false,
    //     touchMove: false,
    //     dragging: false,
    //     slidesToShow: 3.2,
    //     responsive: [
    //         {
    //             breakpoint: 677,
    //             settings: {
    //                 slidesToShow: 1.4
    //             }
    //         }
    //     ]
    // };

    return <>
        <section className="image-marquee">
            {/* <Slider {...settings}>
                <div className="image-marquee--item">
                    <img src="/img/1.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/2.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/8.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/5.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/4.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/6.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/7.jpg" alt="" />
                </div>
                <div className="image-marquee--item">
                    <img src="/img/3.jpg" alt="" />
                </div>
            </Slider> */}
            {/* <div className="ticket-container">
                <div
                    className="ticker-wrap">
                    <div className="ticker">
                        <span className="item-collection-1">
                            <Images />
                        </span>

                        <span className="item-collection-2">
                            <Images />
                        </span>
                    </div>
                </div>
            </div> */}
            <div className="flex img-ticker">
                <img src="/img/1.jpg" alt="" />
                <img src="/img/2.jpg" alt="" />
                <img src="/img/8.jpg" alt="" />
                <img src="/img/5.jpg" alt="" />
                <img src="/img/4.jpg" alt="" />
                <img src="/img/6.jpg" alt="" />
                <img src="/img/7.jpg" alt="" />
                <img src="/img/3.jpg" alt="" />

                <img src="/img/1.jpg" alt="" />
                <img src="/img/2.jpg" alt="" />
                <img src="/img/8.jpg" alt="" />
                <img src="/img/5.jpg" alt="" />
                <img src="/img/4.jpg" alt="" />
                <img src="/img/6.jpg" alt="" />
                <img src="/img/7.jpg" alt="" />
                <img src="/img/3.jpg" alt="" />

            </div>
        </section>
    </>
}



export default Artgallery;