/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useRef, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Web3Context } from "../../web3/contexts/web3Context";
import { poolMethods } from "../../web3/functions/factory";
import { toast } from "react-toastify";

const Redeem3D = (props) => {
  const { networkDetails, loading, setLoading } = useContext(Web3Context);
  const [getPhaseForInstance, setPhaseThreeInstance] = useState();
  const [nftOfGenesis, setNftOfGenesis] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [selectedArt, setSelectedArt] = useState("");
  const [modelData, setModelData] = useState();
  let ViewerRef = useRef();

  useEffect(() => {
    (async () => {
      if (networkDetails && networkDetails.connected) {
        let instanceThree = await poolMethods.getPhaseForInstance(
          networkDetails.web3
        );
        if (instanceThree) {
          setPhaseThreeInstance(instanceThree);
        }
      }
    })();
  }, [networkDetails]);

  const getMergeNftList = async () => {
    if (networkDetails && getPhaseForInstance) {
      setLoading(true);
      const genesisNftList = await poolMethods.nftOfUser(
        getPhaseForInstance,
        networkDetails.address
      );
      setNftOfGenesis(genesisNftList);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (networkDetails && getPhaseForInstance) {
      getMergeNftList();
    }
  }, [getPhaseForInstance]);
  useEffect(() => {
    if (modelData) {
      ViewerRef?.addEventListener("load", () => {
        setLoading(false);
      });
      ViewerRef?.addEventListener("error", () => {
        toast.info("Please try after some time or contact to support");
        setModelData("");
        setSelectedArt("");
        setSelectedId("");
        setLoading(false);
      });
    }
  }, [modelData]);
  const generateResponse = async () => {
    setLoading(true);
    if (selectedArt && selectedId) {
      if (
        modelData &&
        modelData.id === selectedId &&
        modelData.art === selectedArt
      ) {
        return;
      }
      setModelData({ id: selectedId, art: selectedArt });
    }
  };

  const downloadFile = (fileName, data) => {
    console.log(data, "here is the data which will be downloaded");
    const blob = new Blob([JSON.stringify(data, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.gltf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <section className="h-100 d-flex flex-column justify-content-center py-5">
        {loading && (
          <div className="customLoader">
            <TailSpin type="Rings" color="#fff" height={100} width={100} />
          </div>
        )}
        <div className="container-fluid custom_page_style">
          <div className="row align-items-center">
            <div className="col-md-4 mw-530 mt-5 mt-xl-0">
              <div className="border py-5 p-4 p-sm-5 text-white mt-5 mt-xl-0 pt-5">
                <div className="text-center mb-4 mb-sm-5">
                  <h2>Redeem 3D model</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center border-bottom py-3 mb-2">
                  <select
                    className="form-select unstyled"
                    value=""
                    onChange={(e) => {
                      setModelData("");
                      setSelectedId(e.target.value);
                    }}
                  >
                    <option value="" className="option" disabled hidden>
                      Select Token ID
                    </option>
                    {nftOfGenesis.length ? (
                      nftOfGenesis.map((item) => (
                        <option value={item} className="option">
                          {item}
                        </option>
                      ))
                    ) : (
                      <option disabled className="option">
                        Select Token ID
                      </option>
                    )}
                  </select>
                </div>
                <div className="d-flex border-bottom py-3 mb-2">
                  <input
                    className="form-control w-100 text-start"
                    type="text"
                    value={selectedId}
                  />
                </div>
                <div className="d-flex justify-content-between align-items-center border-bottom py-3 mb-2">
                  <select
                    className="form-select unstyled"
                    value=""
                    onChange={(e) => {
                      setModelData("");
                      setSelectedArt(e.target.value);
                    }}
                  >
                    <option value={""} className="option" hidden>
                      With Art
                    </option>
                    <option value="Yes" className="option">
                      Yes
                    </option>
                    <option value="No" className="option">
                      No
                    </option>
                  </select>
                </div>
                <div className="d-flex border-bottom py-3 mb-2">
                  <input
                    className="form-control w-100 text-start"
                    type="text"
                    value={selectedArt}
                  />
                </div>
                {selectedArt && selectedId && (
                  <>
                    {!modelData ? (
                      <button
                        className="btn btn-light btn-lg w-100 mt-5 text-uppercase"
                        data-start-loader
                        type="button"
                        onClick={generateResponse}
                      >
                        Generate Now
                      </button>
                    ) : (
                      <button
                        className="btn btn-light btn-lg w-100 mt-5 text-uppercase"
                        data-start-loader
                        type="button"
                        onClick={() => {
                          downloadFile(selectedId, ViewerRef?.originalGltfJson);
                        }}
                      >
                        {loading ? "Loading" : "Download Now"}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 p-5 model_view">
              {modelData && (
                <model-viewer
                  modes="scene-viewer quick-look webxr"
                  ref={(el) => (ViewerRef = el)}
                  loading="eager"
                  src={`${process.env.REACT_APP_HOST}/generateThreeDModel/${modelData?.id}/${modelData?.art}`}
                  // src={"/try2/try.gltf"}
                  shadow-intensity="1"
                  poster="../../assets/poster-astronaut.png"
                  alt="B&O Speaker NFT 3D Model"
                  camera-controls
                  disable-zoom
                />
              )}
              <div className="d-flex justify-content-end right_btm">
                <a
                  href="http://www.apache.org/licenses/LICENSE-2.0"
                  target="_blank"
                  rel="noreferrer"
                >
                  Copyright 2023 Bang & Olufsen A/S Licensed under the Apache
                  License, Version 2.0 (the "License") :{" "}
                  http://www.apache.org/licenses/LICENSE-2.0
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Redeem3D;
