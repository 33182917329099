
import React from "react";
import { Modal } from "react-bootstrap";
// import blogo from '../../assets/image/B&Ologo.svg';
const Conformation = (props) => {
    let { conformation, alredyMerged, reversable, remember, show, handleClose, onHandleConformation } = props


    return (<>
        <Modal className="c-model" show={show} size="lg" onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter"
            centered >
            {/* <Modal.Header closeButton>
            </Modal.Header> */}
            <Modal.Body>
                <div className="text-center">
                    {/* <h2>{labels.merge.Warning}</h2> */}
                    <div className="mt-5 ">
                        <p className="text-primary">{remember}</p>
                        <p>{alredyMerged}</p>
                        <p>{reversable}</p>
                        <p>{conformation}</p>
                        <p></p>
                    </div>

                    <button className="btn btn-primary" onClick={onHandleConformation} >YES</button>&nbsp;
                    <button className="btn btn-primary" onClick={handleClose}>NO</button>
                </div>

            </Modal.Body>
        </Modal >
    </>
    )
}

export default Conformation