import {
    enviornment
} from "../constants/constants";
import {
    message
} from "../constants/messages";

/**
 * Purpose       : To get Instance of the ticket contract
 * parameter     :
 *  web3        : Current wallet object
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].ERC20ABI,
                    envData[networkId].ERC20Address
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

/**
 * Purpose       : To get Instance phase0 contract
 * parameter     :
 *  web3        : Current wallet object
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getPhase0Instance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].Phase0ABI,
                    envData[networkId].Phase0Address
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

/**
 * Purpose       : To get Instance phase1 contract
 * parameter     :
 *  web3        : Current wallet object
 * Created date  : 15 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getPhaseOneInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].PhaseOneABI,
                    envData[networkId].PhaseOneAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

// /**
// * Purpose       : To get Instance phase2 contract
// * parameter     :
// *  web3        : Current wallet object
// * Created date  : 1 Dec 2022
// * Last updated  :
// * Developer     : Nirav
// */

function getPhaseTwoInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].PhaseTwoABI,
                    envData[networkId].PhaseTwoAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};


/**
 * Purpose       : To get Instance phase3 contract
 * parameter     :
 *  web3        : Current wallet object
 * Created date  : 15 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getPhaseThreeInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].PhaseThreeABI,
                    envData[networkId].PhaseThreeAddress
                );

                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

/**
 * Purpose       : To get Instance phase4 contract
 * parameter     :
 *  web3        : Current wallet object
 * Created date  : 5 Dec 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getPhaseForInstance(web3) {

    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== '') {
            try {
                let envData = enviornment;
                const networkId = await web3.eth.net.getId();
                let Instance = await new web3.eth.Contract(
                    envData[networkId].PhaseForABI,
                    envData[networkId].PhaseForAddress
                );
                if (Instance) {
                    resolve(Instance);
                } else {
                    reject({
                        error: message.web3.instanceError
                    });
                }
            } catch (error) {
                reject(error);
            }
        }
    });
};

/**
 * Purpose       : To get totalSupply
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : Address of the wallet
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Lalit
 */

function getTotalSupply(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .totalSupply()
                .call({
                    from: walletAddress
                }, (err, data) => {

                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        if (data > 0) {
                            resolve(parseFloat(data));
                        } else {
                            resolve(data)
                        }
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Purpose       : To get Owner Counter 
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : Address of the wallet
 * Created date  : 24 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getOwnerCounter(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .ownerCounter()
                .call({
                    from: walletAddress
                }, (err, data) => {

                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        if (data > 0) {
                            resolve(parseFloat(data));
                        } else {
                            resolve(data)
                        }
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Purpose       : To get Public Counter 
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : Address of the wallet
 * Created date  : 24 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function getPublicCounter(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .publicCounter()
                .call({
                    from: walletAddress
                }, (err, data) => {

                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        if (data > 0) {
                            resolve(parseFloat(data));
                        } else {
                            resolve(data)
                        }
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Purpose       : To give permission to the contract to burn ticket
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  web3          : web3 object from which we can get networkId
 *  tokenId       : Id of the token which we are approving for burning
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function approve(ercInstance, walletAddress, web3, tokenId) {
    return new Promise(async (resolve, reject) => {
        if (web3 && web3 !== "") {
            let envData = enviornment;
            const networkId = await web3.eth.net.getId();
            let to = envData[networkId].Phase0Address

            try {
                return await ercInstance.methods
                    .approve(to, tokenId)
                    .send({
                        from: walletAddress
                    })
                    .on("receipt", function (receipt) {
                        resolve(receipt);
                    })
                    .on("error", function (error) {
                        reject({
                            error: error
                        });
                    });
            } catch (error) {
                reject(error);
            }
        }
    });
}

/**
 * Purpose       : Only admin can do ticket minting
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  noOfToken     : Number of ticket minting 
 * Created date  : 20 Oct 2022
 * Last updated  :
 * Developer     : Taruna
 */

function mint(ercInstance, walletAddress, noOfToken) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .mint(noOfToken)
                .send({
                    from: walletAddress
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : Only whitelist user can do ticket minting
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  price         : price of ticket
 * Created date  : 01 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function preSaleMint(ercInstance, walletAddress, price) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .preSaleMint()
                .send({
                    from: walletAddress,
                    value: price
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : Anyone can do ticket minting while public mint duration
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  mintPayingETH : Number of ticket minting
 *  price         : total price of tickets
 * Created date  : 01 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function mintPayingETH(ercInstance, walletAddress, mintPayingETH, price) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .mintPayingETH(mintPayingETH)
                .send({
                    from: walletAddress,
                    value: price
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : To check given address is whitelisted or not
 * parameter     :
 * ercInstance   : Instance of the wallet
 * walletAddress : From wallet address
 * data          : To address which we need to check
 * Created date  : 20 Oct 2022
 * Last updated  :
 * Developer     : Nirav
 */

function whitelistedAddresses(ercInstance, walletAddress, data) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .whitelistedAddresses(data)
                .call({
                    from: walletAddress
                }, (err, data) => {
                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        resolve(data)
                    }
                });
        } catch (error) {

            reject(error);
        }
    });
}

/**
 * Purpose       : Revel first image
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 01 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function redeem(ercInstance, walletAddress, ticketId) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .redeem(ticketId)
                .send({
                    from: walletAddress
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : To fetch number of ticket minted for the address
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Lalit
 */

function tokenMinitCount(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .mintCounter(walletAddress)
                .call({
                    from: walletAddress
                }, (err, data) => {
                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        resolve(data)
                    }
                });
        } catch (error) {

            reject(error);
        }
    });
}

/**
 * Purpose       : To get list of ticket minted by user/admin
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 * Created date  : 04 Nov 2022
 * Last updated  :
 * Developer     : Lalit
 */
function nftOfUser(ercInstance, walletAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .nftOfUser(walletAddress)
                .call({
                    from: walletAddress
                }, (err, data) => {
                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        resolve(data)
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};


/**
 * Purpose       : Claim first image
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 15 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function claim(ercInstance, walletAddress, ticketId) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .claim(ticketId)
                .send({
                    from: walletAddress
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : setApprroval for All (redeem)
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 28 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function setApprovalForAll(ercInstance, walletAddress, operatorAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .setApprovalForAll(operatorAddress, true)
                .send({
                    from: walletAddress
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : check isApproved or not
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 28 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */


function isApprovedForAll(ercInstance, walletAddress, operatorAddress) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .isApprovedForAll(walletAddress, operatorAddress)
                .call({
                    from: walletAddress
                }, (err, data) => {
                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        resolve(data)
                    }
                });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Purpose       : Merge  image
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 1 Dec 2022
 * Last updated  :
 * Developer     : Nirav
 */

function merge(ercInstance, walletAddress, ticketId0, ticketId1) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .merge(ticketId0, ticketId1)
                .send({
                    from: walletAddress
                })
                .on("receipt", function (receipt) {
                    resolve(receipt);
                })
                .on("error", function (error) {
                    reject({
                        error: error
                    });
                });
        } catch (error) {

            reject(error);
        }
    });

}

/**
 * Purpose       : check isApproved or not
 * parameter     :
 *  ercInstance   : Instance of the wallet
 *  walletAddress : From wallet address
 *  ticketId      : token id which image is revel
 * Created date  : 28 Nov 2022
 * Last updated  :
 * Developer     : Nirav
 */

function alreadyClaimed(ercInstance, walletAddress, tokenId) {
    return new Promise(async (resolve, reject) => {
        try {
            return await ercInstance.methods
                .alreadyClaimed(tokenId)
                .call({
                    from: walletAddress
                }, (err, data) => {

                    if (err) {
                        reject({
                            error: err
                        });
                    } else {
                        resolve(data)
                    }

                });
        } catch (error) {
            reject(error);
        }
    });
};

export const poolMethods = {
    getInstance,
    getPhase0Instance,
    getPhaseOneInstance,
    getPhaseTwoInstance,
    getPhaseThreeInstance,
    getPhaseForInstance,
    getTotalSupply,
    getOwnerCounter,
    getPublicCounter,
    approve,
    whitelistedAddresses,
    redeem,
    mint,
    mintPayingETH,
    preSaleMint,
    tokenMinitCount,
    nftOfUser,
    claim,
    setApprovalForAll,
    isApprovedForAll,
    merge,
    alreadyClaimed,
}