import { useContext, useEffect, useState } from 'react';
import { labels } from '../../constant/lable';
import { addWalletAddress } from '../../services/ConnectWallet';
import { Web3Context } from '../../web3/contexts/web3Context';

const ConnectWallet = () => {
    // ** Hook
    let [account, setAccount] = useState(null);
    let [connected, setConnected] = useState(false);

    const { networkDetails, handleConnect, loading } = useContext(Web3Context);
    useEffect(() => {
        if (networkDetails) {
            setAccount(networkDetails.address);
            setConnected(networkDetails.connected);
        }
    }, [networkDetails, loading])

    useEffect(() => {
        if (account) {
            let object = {
                walletAddress: account
            }
            addWalletAddress(object)
        }
    }, [account]);

    const connectWalletSeo = (() => {
        handleConnect()
        if (window?.dataLayer) {
            window.dataLayer.push(labels.homeConnectWallet)
        }
    })

    return (<>
        {
            connected && account && !loading ? <>
                <button className="btn btn-light">{labels.connectWallet.connected}</button>
            </> : (loading ? '' :
                <button className="btn btn-light" onClick={connectWalletSeo}>{labels.connectWallet.connect}</button>
            )
        }
    </>
    )
}

export default ConnectWallet
