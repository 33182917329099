import axios from "axios";

export const getChainalysis = async (address) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/chainalysis?address=` + address,
        method: "get",
        headers: { "Content-Type": "application/json" }
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const setChainalysis = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/chainalysis`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data: formData,
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}