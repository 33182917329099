import ArtVialCheck from "../ArtVialCheck";
import Claim from "./claim";

const ClaimMain = () => {
  return (
    <>
      <div className="claimIndex d-flex h-100 bg-black justify-content-center">
        <div className="claimArt">
          <Claim />
        </div>
        <div className="claimArtCheck">
          <ArtVialCheck />
        </div>
      </div>
    </>
  );
};

export default ClaimMain;
