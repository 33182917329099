import React from "react";

const Faq = () => {
    return <>
        <section id="faq" className="py-5 bg-gray-100">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 py-5 text-center">
                        <h2 data-aos="fade-in">FAQ</h2>
                    </div>
                </div>
                <div className="row pe-5 pe-sm-0">
                    <div className="col-sm-10 offset-sm-1">
                        <div className="accordion accordion-flush" id="faqCollapse">
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-1" itemProp="name">
                                        What is the Bang & Olufsen DNA Collection?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-1" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        A collection of 1925 Non-Fungible Tokens (NFTs) stored on the Ethereum blockchain. Each
                                        NFT represents a unique digital object from Bang & Olufsen.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-2" itemProp="name">
                                        What is the idea behind?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-2" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        The DNA Collection is our first step into Web3 and NFTs. In it, we want to connect the
                                        iconic products and sound of Bang & Olufsen with pioneering artists from the NFT space,
                                        creating a unique collectible for the art, design and music lovers in Web3.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-3" itemProp="name">
                                        Why 1925 NFTs?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-3" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Bang & Olufsen was founded in 1925, so it’s a tip of the hat to our two founders
                                        reminding us to never to be afraid of breaking new ground.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-4" itemProp="name">
                                        How are the NFTs created?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-4" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        To create the 1925 NFTs we’ve broken 4 digital products into elements such as legs,
                                        frame, front cover and back cover, and custom made a range of digital product materials
                                        that can be applied to the products. At mint, the selection of product and product
                                        materials will be randomized, generating a collection of 1925 unique NFTs that combine
                                        Bang & Olufsen product icons and design aesthetic with collector involvement.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-5" itemProp="name">
                                        Which blockchain will Bang & Olufsen mint on?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-5" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        The NFTs will be minted on the Ethereum blockchain. We believe that’s the best choice
                                        for us as Ethereum is now a Proof of Stake (PoS) blockchain, resulting, according to
                                        different reports, in an energy consumption reduction of 99.95% compared to before the
                                        merge when Ethereum was a Proof of Work (PoW) blockchain.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-6" itemProp="name">
                                        Are the NFTs fully on-chain?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-6" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        No. The metadata and digital objects will be stored on IPFS, the most widely adopted
                                        decentralized storage service.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-7" itemProp="name">
                                        When can I buy?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-7" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Minting is currently live! Visit <u><a href="https://dna.nft.bang-olufsen.com/mint" target="_blank">dna.nft.bang-olufsen.com/mint</a></u>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-8" itemProp="name">
                                        Where can I buy?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-8" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        You can purchase a DNA Collection NFT on our mint site www.dna.nft.bang-olufsen.com
                                        using an Ethereum compatible cryptocurrency wallet – such as MetaMask and Coinbase
                                        Wallet – containing sufficient ETH.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-9" itemProp="name">
                                        What is the mint price?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-9" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Bang & Olufsen DNA NFTs will be sold in a "blind sale" for 0.2 ETH (+gas fee). The exact DNA NFT will remain a mystery to the owner until revealed approximately a week after the sale.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-10" itemProp="name">
                                        How do I buy?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-10" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        <ol>
                                            <li>You’ll need a digital wallet to mint an NFT. If you haven’t got one already,
                                                MetaMask is one of Web3’s most popular and user-friendly wallets.
                                            </li>
                                            <li>While mobile is possible, for the best experience we recommend connecting via a
                                                desktop browser using your chosen wallet’s browser extension (such as MetaMask
                                                or Coinbase)
                                            </li>
                                            <li>Click ‘Connect Wallet’ at the top right of the page and follow the
                                                instructions.
                                            </li>
                                            <li>Make sure your digital wallet has Ethereum (ETH). You can buy ETH in MetaMask,
                                                or deposit it from elsewhere.
                                            </li>
                                            <li>Click ‘Mint’ and confirm the transaction in your wallet. The blockchain
                                                confirmation may take a few minutes depending on traffic on the Ethereum
                                                blockchain.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-11" itemProp="name">
                                        How many NFTs can I buy?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-11" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        You can purchase 4 DNA Collection NFTs per wallet.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-12" itemProp="name">
                                        Will there be an allow list and a presale?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-12" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Yes, we’ll create an allow list of wallets that will be able to mint Bang & Olufsen DNA
                                        Collection NFTs in the 24 hours leading up to the public sale. Qualification criteria
                                        can be found on our <a href="https://discord.gg/yrBajM5nmP" target="_blank" rel="noreferrer">Discord</a>.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-13" itemProp="name">
                                        Where can I buy and trade once the mint has sold out?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-13" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        You can buy and sell our NFT collection on marketplaces such as <a
                                            href="https://opensea.io/" target="_blank" rel="noreferrer">OpenSea</a> once the mint has sold
                                        out.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-14" itemProp="name">
                                        What are the Phases of the Generation 1 NFT’s?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-14" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        <p>Our Generation 1 Roadmap is comprised by four phases.</p>
                                        <p>Phase 1 is the mint and reveal of the DNA Collection NFT. The NFT is randomly
                                            generated based on a pre-defined distribution of products and product traits.
                                            Neither the buyer nor Bang & Olufsen will know which products and which product
                                            traits will be selected as these are randomly assigned.</p>
                                        <p>In Phase 2, DNA Collection NFT owners will receive an Art Vial containing a piece of
                                            art from one of our visual artist collaborators (Hackatao, Thomas Lin, Shavonne Wong
                                            and the Bang & Olufsen Design Studio). The Art Vial will contain metadata such as
                                            name of the artist but will not display the artwork itself. The artwork will only be
                                            revealed upon merging the DNA Collection NFT with the Art Vial on our minting site
                                            (www.dna.nft.bang-olufsen.com) in a defined time window. By means of the merge
                                            process, the artwork contained in the Art Vial will be applied to the surface of the
                                            DNA Collection NFT, creating a new NFT and burning the DNA Collection NFT and the
                                            Art Vial in the process.</p>
                                        <p>In Phase 3, DNA Collection NFT owners will receive a Music Vial containing a piece of
                                            art from one of our music artist collaborators which will be announced during
                                            November and December. The Music Vial will contain metadata such as name of the
                                            artist but will not play the music track. This will only be revealed upon merging
                                            the DNA Collection NFT with the Music Vial on our minting site
                                            (www.dna.nft.bang-olufsen.com) in a defined time window. By means of the merge
                                            process, the music track contained in the Music Vial will be applied to the DNA
                                            Collection NFT, creating a new NFT and burning NFT from Phase 2 in the process.</p>
                                        <p>In Phase 4 Bang & Olufsen DNA Collection NFT owners that have merged their DNA
                                            Collection NFT with both the Art Vial and the Music Vial will:</p>
                                        <ol>
                                            <li>Receive a metaverse ready 3D version of their NFT.</li>
                                            <li>Get token gated access to purchase a limited edition run of our A9 speaker with
                                                art from our collaborating artists on the product cover.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-15" itemProp="name">
                                        What other benefits will I receive as an DNA Collection NFT holder?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-15" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        All DNA Collection NFT owners will be given allow list access to future Bang & Olufsen
                                        NFT drops. Check out our Discord for a full view of our evolving benefits.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-16" itemProp="name">
                                        What can I do with my DNA Collection NFT?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-16" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        You can make any personal, non-commercial use you would like. You can download the file,
                                        show it off online, text it to your friends, email it to your mum, or stare at it in
                                        your wallet. In the future, you will be able to show off your DNA Collection NFTs across
                                        metaverses such as Decentraland, OnCyber, and Open Metaverse.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-17" itemProp="name">
                                        Who receives the revenue and royalty?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-17" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Revenues from primary sales (mint) and the royalties from secondary market sales will be
                                        split with a share for Bang & Olufsen and a share for the collaborating artists.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-18" itemProp="name">
                                        Will B&O release more NFTs in the future?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-18" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        Yes, but it is too early to reveal the nature of our coming initiatives, but as an owner
                                        of a DNA Collection NFT you will get the chance to join the allow lists for future
                                        drops.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item" itemScope itemProp="mainEntity" itemType="http://schema.org/Question">
                                <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#faq-19" itemProp="name">
                                        What has Bang & Olufsen done to minimize the climate impact of the NFTs?
                                        <em></em>
                                    </button>
                                </h2>
                                <div id="faq-19" className="accordion-collapse collapse" itemScope itemProp="acceptedAnswer"
                                    itemType="http://schema.org/Answer">
                                    <div className="accordion-body" itemProp="text">
                                        The DNA Collection NFT lives on the Ethereum blockchain, which based on Proof of Stake
                                        (PoS). Compared to earlier Proof of Work based versions of Ethereum reduces, according
                                        to reports, the amount of computational work needed to verify blocks and transactions
                                        that keep the blockchain secure by 99.95%.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Faq;