import { useEffect, useState } from "react";
import "./App.css";
import "./App.scss";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import Mint from "./Component/Mint";

import Nevigation from "./Component/Nevigation";

import Claim from "./Component/Claim";
import Main from "./Component/Main";
import Merge from "./Component/Merge";
import MergeAndReveal from "./Component/MergeAndReveal";
import Redeem from "./Component/Redeem";

import { TailSpin } from "react-loader-spinner";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MusicVialIndex from "./Component/MusicVialClaim";
import Socialmenu from "./Component/Socialmenu";
import Whitelist from "./Component/Whitelist";
import { getLinks } from "./services/Layout/index";
import Reedem3D from "./Component/Reedem3D";
import Order from "./Component/Order";

function App() {
  const [showRedeem, setShowRedeem] = useState();
  const [openMenu, setOpenMenu] = useState(false);
  const [load, setLoad] = useState(false);
  const [showClaim, setShowClaim] = useState(false);
  const [showMerge, setShowMerge] = useState(false);
  const [showMusicVialClaim, setShowMusicVialClaim] = useState(false);
  const [showMeargeAndReveal, setShowMergeAndReveal] = useState(false);

  const [open, setOpen] = useState(false);
  const onHandleClick = () => {
    setOpen(!open);
    onHandleOpen();
  };

  const onHandleOpen = () => {
    setOpenMenu(!openMenu);
    if (!openMenu) {
      document.body.classList.add("navscrollhide");
    } else {
      document.body.classList.remove("navscrollhide");
    }
  };
  const onHandleClose = () => {
    setOpen(!open);
  };

  const [links, setLinks] = useState({});

  useEffect(() => {
    setLoad(true);
    getLinks()
      .then((result) => {
        if (result && result.code === 200) {
          const details = result.data;
          setLinks(details);
        }
        setLoad(false);
      })
      .catch((e) => {
        setLoad(false);
      });
  }, []);

  useEffect(() => {
    if (links) {
      let redeemDate = new Date(links?.redeemDate).getTime() * 1000;
      const interval = setInterval(async () => {
        let redeemTime = new Date(redeemDate).getTime();
        let currDate = new Date().getTime();
        if (currDate > redeemTime) {
          setShowRedeem(true);
        } else {
          setShowRedeem(false);
        }

        if (links?.claimDate !== undefined && links?.claimDate !== null) {
          let claimDate = new Date(links?.claimDate).getTime() * 1000;
          if (currDate > claimDate) {
            setShowClaim(true);
          } else {
            setShowClaim(false);
          }
        }

        if (links?.mergeDate !== undefined && links?.mergeDate !== null) {
          let mergeDate = new Date(links?.mergeDate).getTime() * 1000;
          if (currDate > mergeDate) {
            setShowMerge(true);
          } else {
            setShowMerge(false);
          }
        }

        if (
          links?.musicVialClaimDate !== undefined &&
          links?.musicVialClaimDate !== null
        ) {
          let musicVialClaimDate =
            new Date(links?.musicVialClaimDate).getTime() * 1000;
          if (currDate > musicVialClaimDate) {
            setShowMusicVialClaim(true);
          } else {
            setShowMusicVialClaim(false);
          }
        }

        if (
          links?.mergeRevealDate !== undefined &&
          links?.mergeRevealDate !== null
        ) {
          let mergeRevealDate =
            new Date(links?.mergeRevealDate).getTime() * 1000;
          if (currDate > mergeRevealDate) {
            setShowMergeAndReveal(true);
          } else {
            setShowMergeAndReveal(false);
          }
        }
        // setCountDown(newtime);
      }, 200);
      return () => clearInterval(interval);
    }
  }, [links]); // eslint-disable-line

  useEffect(() => {
    if (window?.dataLayer) {
      window.dataLayer.push({
        event: "pageLoad",
        url: window.location.href,
      });
    }
  }, []);
  return (
    <div className="App">
      {load && (
        <div className="customLoader">
          <TailSpin type="Rings" color="#fff" height={100} width={100} />
        </div>
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main date={links} />}></Route>
          <Route path="/redeem3d" element={<Reedem3D date={links} />}></Route>
          <Route path="/order" element={<Order date={links} />}></Route>
          <Route path="/mint" element={<Mint date={links} />}></Route>
          <Route
            path={"/redeem"}
            element={
              links?.isRedeemShow === true ? (
                <Redeem date={links} />
              ) : (
                <Main date={links} />
              )
            }
          ></Route>
          <Route path="/whitelist" element={<Whitelist />}></Route>
          <Route
            path="/claim"
            element={
              links?.isClaimShow === true ? (
                <Claim date={links} />
              ) : (
                <Main date={links} />
              )
            }
          ></Route>
          {/* <Route path="/artvialcheck" element={links?.isClaimShow === true ? <ArtVialCheck date={links} /> : <Main date={links} />}></Route> */}
          <Route
            path="/merge"
            element={
              links.isMergeShow === true ? (
                <Merge date={links} />
              ) : (
                <Main date={links} />
              )
            }
          ></Route>
          <Route
            path="/musicVialClaim"
            element={
              links?.isMusicVialClaimShow === true ? (
                <MusicVialIndex date={links} />
              ) : (
                <Main date={links} />
              )
            }
          ></Route>
          <Route
            path="/mergeAndReveal"
            element={
              links?.isMergeRevealShow === true ? (
                <MergeAndReveal date={links} />
              ) : (
                <Main date={links} />
              )
            }
          ></Route>
        </Routes>
      </BrowserRouter>
      <Header onHandleClick={onHandleClick} open={open} />
      <Nevigation
        showRedeem={showRedeem}
        openMenu={openMenu}
        onHandleOpen={onHandleOpen}
        onHandleClose={onHandleClose}
        showClaim={showClaim}
        showMerge={showMerge}
        showMusicVialClaim={showMusicVialClaim}
        showMeargeAndReveal={showMeargeAndReveal}
      />
      <Socialmenu />
      <Footer />
    </div>
  );
}

export default App;
