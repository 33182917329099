export const labels = {
    whitelist: {
        checkWhitelist: "Check Whitelist",
        whitelist: "Whitelist",
        check: "Check",
        whitelisted: "User Whitelisted",
        whiteListedMsg: "User is not whitelisted",
        wallateError: "To check whitelisted addresses, please connect Metamask wallet."
    },
    artVialCheck: {
        artVialCheckList: "Write the number of your phase 1 NFT",
        artVialCheck: "Art Vial Claimed?",
        check: "Check",
        artViallisted: "Art vial has been claimed for",
        artVialCheckedMsg: "Art vial has not been claimed for",
        artVialCheckError: "To check claim or not, please connect Metamask wallet."
    },
    musicVialCheck: {
        musicVialCheckList: "Write the number of your phase 2 NFT",
        musicVialCheck: "Music Vial Claimed?",
        check: "Check",
        musicViallisted: "Music vial has been claimed for",
        musicVialCheckedMsg: "Music vial has not been claimed for",
        musicVialCheckError: "To check claim or not, please connect Metamask wallet."
    },
    minting: {
        mintSuccess: 'You successfully Minted a B&O DNA NFT',
        opensea: 'Please check your wallet on OpenSea',
        limitExceeded: 'Limit Exceeded.',
        preSale: 'mint allowed for the pre-mint.',
        mint: "mint",
        amountMessage: "Please enter Amount."
    },
    redeem: {
        redeemSuccess: 'You have successfully minted this drop',
        opensea: 'Please go to Opensea and refresh the metadata of the NFT / NFTs'
    },
    artClaim: {
        artClaimSuccess: 'You have successfully claimed an art vial',
        opensea: 'Please go to Opensea and refresh the metadata of the NFT / NFTs'
    },
    connectWallet: {
        connect: "Connect Wallet",
        connected: "Connected"
    },
    homeConnectWallet: {
        event: 'Click',
        eventAction: 'click_cta',
        eventCategory: 'home',
        eventLabel: 'connect wallet',
    },
    homeMint: {
        event: 'Click',
        eventAction: 'click_cta',
        eventCategory: 'home',
        eventLabel: 'mint',
    },
    claimAtrVialClaimNow: {
        event: 'Click',
        eventAction: 'click_cta',
        eventCategory: 'claim art vial page',
        eventLabel: 'claim now ',
    },
    mergeNow: {
        event: 'Click',
        eventAction: 'click_cta',
        eventCategory: 'merge and reveal nft with art vial page',
        eventLabel: 'merge now',
    },
    redeemNow: {
        event: 'Click',
        eventAction: 'click_cta',
        eventCategory: 'redeem page',
        eventLabel: 'redeem now',
    },
    claim: {
        success: "Token claim successfully.",
        validationError: "Please select token-id."
    },
    merge: {
        mergeSuccess: 'You have succesfully merged your B&O DNA NFT and art vial',
        opensea: 'Please go to Opensea and refresh the metadata of the NFT / NFTs',
        validationError: "Please enter token-id.",
        remember: "Remember to claim your art vial, and make sure that your Phase 1 B&O DNA NFT is showing on opensea before proceeding with the merge",
        conformation: "Are you sure you would like to continue?",
        alredyMerged: "Your B&O DNA NFT will be fully or partially altered by merging it with an art vial",
        reversable: "This is a non-reversable transaction",

        Warning: "Warning!"
    },
    musicVialClaim: {
        success: "Music vial claim successfully.",
        validationError: "Please select token-id.",
        musicVialClaimSuccess: 'You have successfully claimed an music vial',
        opensea: 'Please go to Opensea and refresh the metadata of the NFT / NFTs'
    },
    meargeAndReveal: {
        success: "Merge Music vial successfully.",
        formikError: "Please select token-id.",
        mergeAndRevealSuccess: 'You have succesfully merged your B&O DNA NFT and music vial',
        opensea: 'Please go to Opensea and refresh the metadata of the NFT / NFTs',
        remember: "Remember to claim your music vial, and make sure that your Phase 2 B&O DNA NFT is showing on opensea before proceeding with the merge",
        note: "NOTE: You will get NFT on opensea in 10-15 minutes",
        conformation: "Are you sure you would like to continue?",
        alredyMerged: "Your B&O DNA NFT will be fully or partially altered by merging it with an audio vial",
        reversable: "This is a non-reversable transaction",
    },
    threeDModel: {
        success: "3d Model generated successfully.",
    }
}