import React from "react";

const Projects = () => {
    return <>
        <section id="project" className="position-relative h-60 py-sm-5 bg-light d-flex flex-column justify-content-center">
            <div className="background-image">
                <img src="/img/project.jpg" alt="" />
            </div>
            <div className="container-fluid py-sm-5">
                <div className="row">
                    <div className="col-sm-4 offset-sm-7 py-sm-5 text-dark text-sm-light">
                        <div className="py-5 pe-5 pe-sm-0 toggle-section">
                            <div className="toggle-content" id="toggleContent1">
                                <p className="subtitle mb-2" data-aos="fade-up" data-aos-delay="0">The collectibles</p>
                                <h2 className="mb-4" data-aos="fade-up" data-aos-delay="100">The DNA of Bang & Olufsen</h2>
                                <p className="mb-4" data-aos="fade-up" data-aos-delay="200">We’re excited to launch the Bang & Olufsen DNA Collection – an edition of 1925 digital collectibles created in collaboration with a group of incredible Web3 artists to launch us into the Metaverse and provide token-gated access to future exclusive Bang & Olufsen products and experiences.</p>
                            </div>
                            <div className="toggle-content" id="toggleContent2">
                                <p className="subtitle mb-2" data-aos="fade-up" data-aos-delay="0">The art</p>
                                <h2 className="mb-4" data-aos="fade-up" data-aos-delay="100">The DNA of Crypto Art & Music</h2>
                                <p className="mb-4" data-aos="fade-up" data-aos-delay="200">We are working with pioneering artists and musicians from web3 to pay homage to the vibrant creator culture, and to kickstart new ways of collaborating, remixing products, and creating community across the metaverse and the physical world.</p>
                            </div>
                            <div className="d-flex gap-3" data-aos="fade-in" data-aos-delay="500">
                                <button className="btn btn-outline-xs-dark btn-outline-light" data-content-toggle>
                                    The Collectibles
                                </button>
                                <button className="btn btn-outline-xs-dark btn-outline-light" data-content-toggle>
                                    The Crypto Art
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Projects;