import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { labels } from '../../constant/lable';
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';
import Success from '../Modal/success';

const Mint = (props) => {
    const { date } = props;
    const { networkDetails, setLoading, loading } = useContext(Web3Context);
    const [countDownDate, setCountDownDate] = useState(null);
    const [countDown, setCountDown] = useState(0);
    const [totalAmount, setTotalAmount] = useState(date.price);
    const [buttonShow, setButtonShow] = useState(true);
    const [message, setMessage] = useState(true);
    const [getInstance, setInstance] = useState();
    const [dateStatus, setDateStatus] = useState("")
    const [admin, setAdmin] = useState(false);
    const [mintMax, setMintMax] = useState(1);
    const [totalTokenMinitCount, setTotalTokenMinitCount] = useState(0);
    const [totalSupply, setTotalSupply] = useState('-')
    const [startMint, setStartMint] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false); // mint success modal close

    const onHandlePlus = (e) => { // plus mint count
        let mintLimit = networkDetails.isAdmin === true ? 5 : 4
        if (formik.values.amount < mintLimit) {
            formik.setFieldValue('amount', Number(formik.values.amount) + 1)
            setTotalAmount((Number(date?.price) * Number(formik.values.amount + 1)))
        }
    }
    const onHandleMinus = (e) => { //minus mint count
        if (formik.values.amount > 1) {
            formik.setFieldValue('amount', Number(formik.values.amount) - 1)
            setTotalAmount((Number(date?.price) * Number(formik.values.amount - 1)))
        }

    }

    useEffect(() => {
        if (date.price) { // To set default price to the total supply
            setTotalAmount(date.price);
        }
    }, [date, networkDetails])

    const getTotalSupplyCount = async () => {
        let instance = await poolMethods.getInstance(networkDetails.web3)
        let ownerCount;
        let publicCount;
        if (instance) {
            await poolMethods.getOwnerCounter(instance, networkDetails.address).then((data) => {
                ownerCount = data
            }).catch((err) => {
                console.log(err)
            })

            await poolMethods.getPublicCounter(instance, networkDetails.address).then((data) => {
                publicCount = data
            }).catch((err) => {
                console.log(err)
            })

            if (ownerCount !== undefined && publicCount !== undefined) {
                let count = parseInt(ownerCount) + parseInt(publicCount)
                setTotalSupply((process.env.REACT_APP_TOTAL_NFT - count))
            }
        }
    }

    useEffect(() => {
        ; (async () => {

            if (networkDetails.connected && date.contractDeploymentDate !== undefined) {

                let startDate = new Date(date?.contractDeploymentDate).getTime() * 1000; // public mint start time
                let endDate = new Date(date?.contractDeploymentEndDate).getTime() * 1000; // public mint end time

                setCountDownDate(startDate)

                let isWhitelistuser = networkDetails.isWhitelist;
                let isAdmin = networkDetails.isAdmin;

                setAdmin(networkDetails.isAdmin)

                let currDate = new Date().getTime();
                let whitelistStart = (startDate - (Number(process.env.REACT_APP_PRESALESTART) * 60 * 1000));  // set whitelist time in hour
                let whitelistEnd = (startDate - (Number(process.env.REACT_APP_BANNEDTIME) * 60 * 1000)); // set buffer or ban time in hour


                let instance = await poolMethods.getInstance(networkDetails.web3)
                if (instance) {
                    setInstance(instance)
                }
                // Fech & set total supply NFT
                await getTotalSupplyCount()

                // Fech & set users mint count
                let tokenMinitCount = await poolMethods.tokenMinitCount(instance, networkDetails.address)
                setTotalTokenMinitCount(tokenMinitCount);


                if (currDate > whitelistStart && currDate < whitelistEnd) {
                    setStartMint(true)
                    setButtonShow(true)
                    setDateStatus("preSale")

                    if (isWhitelistuser === true && admin === false) {
                        setMintMax(process.env.REACT_APP_USER_ONE_TIME_LIMIT)
                        if (tokenMinitCount >= Number(process.env.REACT_APP_USER_ONE_TIME_LIMIT)) {
                            setMessage(labels.minting.limitExceeded)
                            setButtonShow(false)
                            setStartMint(false)
                        }
                    } else {
                        if (admin === true) {
                            setStartMint(true)
                            setMintMax(process.env.REACT_APP_ADMIN_ONE_TIME_LIMIT)
                            if (tokenMinitCount >= Number(process.env.REACT_APP_ADMIN_MINT_LIMIT)) {
                                setMessage(labels.minting.limitExceeded)
                                setButtonShow(false)
                                setStartMint(false)
                            }
                        } else {
                            if (tokenMinitCount >= Number(process.env.REACT_APP_USER_ONE_TIME_LIMIT)) {
                                setStartMint(false)
                                setButtonShow(false)
                            } else {
                                setStartMint(false)
                            }

                            //setButtonShow(false)
                        }
                    }
                } else if (currDate > startDate && currDate < endDate) {
                    setStartMint(true)
                    setDateStatus("mintPay")
                    setButtonShow(true)

                    if (isAdmin) {
                        setMintMax(process.env.REACT_APP_ADMIN_ONE_TIME_LIMIT)
                        if (tokenMinitCount >= Number(process.env.REACT_APP_ADMIN_MINT_LIMIT)) {
                            setMessage(labels.minting.limitExceeded)
                            setButtonShow(false)
                            setStartMint(false)
                        }
                    } else {
                        setMintMax(process.env.REACT_APP_USER_LIMIT)
                        if (tokenMinitCount >= Number(process.env.REACT_APP_USER_LIMIT)) {
                            setMessage(labels.minting.limitExceeded)
                            setButtonShow(false)
                            setStartMint(false)
                        }
                    }

                } else if (currDate > whitelistEnd && currDate < startDate) {
                    setStartMint(false)
                } else {
                    if (tokenMinitCount >= Number(process.env.REACT_APP_USER_LIMIT)) {
                        setStartMint(false)
                        setButtonShow(false)
                    } else {
                        setStartMint(false)
                        setButtonShow(false)
                    }
                }
            }

        })();
    }, [date, networkDetails, startMint, totalTokenMinitCount, countDown, show]) // eslint-disable-line

    useEffect(() => {
        if (getInstance) {
            const interval = setInterval(async () => {
                await getTotalSupplyCount()

                let newtime = new Date(countDownDate).getTime() - new Date().getTime()
                setCountDown(newtime);
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [countDownDate]); // eslint-disable-line


    let mintError = dateStatus === 'preSale' ? labels.minting.preSale : labels.minting.mint
    const validateSchema = Yup.object().shape({
        amount: Yup.number()
            .required(labels.minting.amountMessage)
            .min(1, 'Minimum 1')
            .max(mintMax, 'Max ' + mintMax + mintError)
    })
    // ** Form Value
    const initialValues = {
        amount: 1,
    }
    // ** Formic Form
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values) => {
            if (networkDetails && getInstance && values.amount) {

                setLoading(true)
                if (admin) {
                    if (Number(totalTokenMinitCount) >= Number(process.env.REACT_APP_ADMIN_MINT_LIMIT)) {
                        //Limit Exceeded
                        toast.error(labels.minting.limitExceeded);
                        setLoading(false)
                    } else {
                        mint(values.amount)
                    }
                } else {
                    let validator;
                    if (dateStatus === "preSale") {
                        validator = Number(process.env.REACT_APP_USER_ONE_TIME_LIMIT);
                        if (Number(totalTokenMinitCount) > 0) {
                            validator = Number(totalTokenMinitCount) + values.amount
                        } else {
                            validator = Number(process.env.REACT_APP_USER_ONE_TIME_LIMIT);
                        }
                    } else if (dateStatus === "mintPay") {
                        if (Number(totalTokenMinitCount) > 0) {
                            validator = Number(totalTokenMinitCount) + values.amount
                        } else {
                            validator = Number(process.env.REACT_APP_USER_LIMIT);
                        }

                    } else {

                    }

                    if (validator > process.env.REACT_APP_USER_LIMIT) {
                        //Limit Exceeded
                        toast.error(labels.minting.limitExceeded);
                        setLoading(false)
                    } else {
                        mint(values.amount)
                    }
                }
            }
        },
    });
    const mint = async (noOfUri) => { // sc contract method call for mint
        let price = date.price * 1e18 * noOfUri;
        if (networkDetails) {
            if (networkDetails.isAdmin === true) {
                await poolMethods.mint(getInstance, networkDetails.address, noOfUri).then(async (data) => {
                    await getTotalSupplyCount()
                    setLoading(false)
                    if (data) {
                        setShow(true)
                    }
                }).catch((error) => {
                    toast.error(error.message);
                    setLoading(false)
                });
            } else {
                if (dateStatus === "preSale") {
                    await poolMethods.preSaleMint(getInstance, networkDetails.address, price).then(async (data) => {
                        await getTotalSupplyCount()
                        setLoading(false)
                        if (data) {
                            setShow(true)
                        }
                    }).catch((error) => {
                        toast.error(error.message);
                        setLoading(false)
                    });

                } else if (dateStatus === "mintPay") {
                    await poolMethods.mintPayingETH(getInstance, networkDetails.address, noOfUri, price).then(async (data) => {
                        await getTotalSupplyCount()
                        setLoading(false)
                        if (data) {
                            setShow(true)
                        }
                    }).catch((error) => {
                        toast.error(error.message);
                        setLoading(false)
                    });
                } else {
                    setLoading(false)
                    // nothing to do
                }
            }
        }
    };


    useEffect(() => {
        if (networkDetails && networkDetails.connected) {
            formik.setFieldValue('amount', 1)
        }
    }, [networkDetails])// eslint-disable-line

    return (
        <>
            < Success successMsg={labels.minting.mintSuccess} opensea={labels.minting.opensea} show={show} handleClose={handleClose} />

            <section className="h-100 bg-black d-flex flex-column justify-content-center py-5">
                {loading &&
                    <div className="customLoader">
                        <TailSpin
                            type="Rings"
                            color="#fff"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <div className="border py-5 p-4 p-sm-5 text-white">
                                <div className="text-center mb-5">
                                    <h2>Mint NFT</h2>
                                    <p className="lead fw-normal text-primary mb-0">{totalSupply} NFT's left</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                    <div className="subtitle">Amount</div>
                                    <div className="quantity">
                                        <span className="change" data-qty="minus" onClick={onHandleMinus}>—</span>
                                        <input min="1" className="form-control" type="number" id="quantity" name="quantity"
                                            value={formik.values.amount}
                                            onChange={(e) => {
                                                let mintLimit = networkDetails.isAdmin === true ? 5 : 4
                                                if (Number(e.target.value) <= mintLimit) {
                                                    formik.handleChange('amount')(e)
                                                    setTotalAmount((Number(date?.price) * Number(e.target.value)))
                                                } else {
                                                    formik.setFieldValue('amount', 1)
                                                    setTotalAmount((Number(date?.price) * 1))
                                                }
                                            }}
                                        />
                                        <span className="change"
                                            // data-qty="minus"
                                            onClick={onHandlePlus}>+</span>
                                    </div>
                                </div>
                                {formik.touched.amount && formik.errors.amount ? (
                                    <p className='validationMsg'>
                                        {formik.errors.amount}
                                    </p>
                                ) : null}
                                <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                    <div className="subtitle">Total</div>
                                    <div className="h4 mb-0" data-mint-total>{totalAmount ? totalAmount.toString().slice(0, 6) : ''}</div>
                                </div>

                                {networkDetails?.connected && startMint && buttonShow === true &&
                                    <button className="btn btn-light btn-lg w-100 mt-5" data-start-loader type="button" onClick={formik.handleSubmit}>
                                        Mint now
                                    </button>
                                }

                                {
                                    buttonShow === false && startMint === false &&
                                    <p className='validationMsg'>{message}</p>
                                }
                            </div>
                            <div className="mt-3 text-white small text-center">
                                Each B&O DNA NFT is unique and created by B&O
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Mint;