import React from "react";

const Roadmap = () => {
    return <>
        <section id="roadmap" className="pt-5 pb-0 pb-sm-5 bg-dark text-light overflow-hidden">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 py-5">
                        <h2 data-aos="fade-in">Roadmap</h2>
                    </div>
                </div>
            </div>
            <div className="timeline mt-sm-5 pb-sm-5">
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="200">
                    <div className="badge timeline--event-date">Oct 26th 2022</div>
                    <div className="subtitle">Community</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="400">
                    <div className="badge timeline--event-date">Nov 29th 2022</div>
                    <div className="subtitle">Pre-sale</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="600">
                    <div className="badge timeline--event-date">Nov 30th 2022</div>
                    <div className="subtitle">Public mint phase I</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="800">
                    <div className="badge timeline--event-date">Dec 7th 2022</div>
                    <div className="subtitle">NFT reveal</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="1000">
                    <div className="badge timeline--event-date">Dec 14th 2022</div>
                    <div className="subtitle">Public mint phase II</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="1200">
                    <div className="badge timeline--event-date">Dec 20th 2022</div>
                    <div className="subtitle">Art vial claim</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="1400">
                    <div className="badge timeline--event-date">Jan 5th 2023</div>
                    <div className="subtitle">Merge & reveal</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="1600">
                    <div className="badge timeline--event-date">Feb 9th 2023</div>
                    <div className="subtitle">Music vial claim and merge</div>
                </div>
                {/* <div className="timeline--event" data-aos="fade-in" data-aos-delay="1600">
                    <div className="badge timeline--event-date">Jan 26th 2023</div>
                    <div className="subtitle">Music vial claim</div>
                </div>
                <div className="timeline--event" data-aos="fade-in" data-aos-delay="1800">
                    <div className="badge timeline--event-date">TBA 2023</div>
                    <div className="subtitle">Merge & reveal</div>
                </div> */}
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="2000">
                    <div className="badge timeline--event-date">MARCH 28TH 2023</div>
                    <div className="subtitle">3D models</div>
                </div>
                <div className="timeline--event completed" data-aos="fade-in" data-aos-delay="2200">
                    <div className="badge timeline--event-date">JUNE 21ST 2023</div>
                    <div className="subtitle">Physical product</div>
                </div>
            </div>
        </section>
    </>
}

export default Roadmap;