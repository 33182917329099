import React, { useContext, useEffect, useState } from "react";
import Accordion from 'react-bootstrap/Accordion';
import { poolMethods } from "../../web3/functions/factory";
import { Web3Context } from "../../web3/contexts/web3Context";

const Nevigation = ({ showRedeem, openMenu, onHandleOpen, onHandleClose, showClaim, showMerge, showMusicVialClaim, showMeargeAndReveal }) => {

    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [placeOrderVisible, setPlaceOrderVisible] = useState(true)

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instanceTwo = await poolMethods.getPhaseTwoInstance(networkDetails.web3)
                let instancePhase4 = await poolMethods.getPhaseForInstance(networkDetails.web3)

                let listPhase2 = [], listPhase4 = []
                if (instanceTwo || instancePhase4) {
                    listPhase2 = await poolMethods.nftOfUser(instanceTwo, networkDetails.address)
                    listPhase4 = await poolMethods.nftOfUser(instancePhase4, networkDetails.address)
                }
                if ((listPhase2 && listPhase2.length) || (listPhase4 && listPhase4.length)) {
                    setPlaceOrderVisible(true)
                } else {
                    setPlaceOrderVisible(false)
                }
            }
        })();
    }, [networkDetails])

    const onHandleClick = () => {
        onHandleOpen()
        onHandleClose()
    }
    return <>
        <div className={openMenu ? "navigation-overlay open" : "navigation-overlay"} data-navigation>
            {
                <div className="container-fluid">
                    <ul>
                        <li onClick={onHandleClick}>
                            <a href="/#project">The project</a>
                        </li>
                        <li onClick={onHandleClick}>
                            <a href="/#vials">Art vials</a>
                        </li>
                        <li onClick={onHandleClick}>
                            <a href="/#artists">Artists</a>
                        </li>
                        <li onClick={onHandleClick}>
                            <a href="/#roadmap">Roadmap</a>
                        </li>
                        <li onClick={onHandleClick}>
                            <a href="/#faq">FAQ</a>
                        </li>
                        {
                            placeOrderVisible &&
                            <li onClick={onHandleClick}>
                                <a href="/order">Place Order</a>
                            </li>
                        }
                        {/* <li onClick={onHandleClick}>
                            <a href="/whitelist">Whitelist</a>
                        </li> */}
                    </ul>
                    <ul>
                    <li>
                    <Accordion className="border-0 nav_nft_option">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header className="text-white">Activate your NFT</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    {showRedeem === true &&
                                        <li onClick={onHandleClick}>
                                            <a href="/redeem">Redeem</a>
                                        </li>
                                    }
                                    {showClaim === true &&
                                        <><li onClick={onHandleClick}>
                                            <a href="/claim">Art Vial Claim</a>
                                        </li>
                                            {/* <li onClick={onHandleClick}>
                                    <a href="/artVialCheck">Art Vial Claim Check</a>
                                </li> */}
                                        </>
                                    }
                                    {showMerge === true &&
                                        <li onClick={onHandleClick}>
                                            <a href="/merge">Art Vial Merge</a>
                                        </li>
                                    }
                                    {showMusicVialClaim === true &&
                                        <li onClick={onHandleClick}>
                                            <a href="/musicVialClaim">Music vial Claim</a>
                                        </li>
                                    }
                                    {showMeargeAndReveal === true &&
                                        <li onClick={onHandleClick}>
                                            <a href="/mergeAndReveal">Music vial Merge</a>
                                        </li>
                                    }
                                    {
                                        showMeargeAndReveal === true &&
                                        <li onClick={onHandleClick}>
                                            <a href="/redeem3d">3D Models</a>
                                        </li>
                                    }
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    </li>
                    </ul>
                    
                </div>
            }
        </div>
    </>
}

export default Nevigation;