import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { message } from '../constants/messages';
import { Web3Provider } from "../contexts/web3Context";
import { listenAccountChange, listenNetworkChange, loadBlockChainData, loadWeb3 } from "../functions/web3";
var initialState = {
    address: "",
    web3: "",
    connected: false,
    connectTag: "",
    chainData: "",
    wallet: "",
    chainId: "",
    networkId: "",
    balance: "",
}
const Providers = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [networkDetails, setNetworkDetails] = useState(initialState);

    const resetApp = async () => {
        setNetworkDetails({ ...initialState });
        const web3 = window.web3;
        if (web3 && web3.currentProvider && web3.currentProvider.disconnect) {
            await web3.currentProvider.disconnect();
            localStorage.clear()
        }
    };

    const handleConnect = async () => {
        const metaMaskInstalled = typeof window.web3 !== "undefined";
        if (metaMaskInstalled) {
            setLoading(true);
            await loadWeb3(setLoading);
            await loadBlockChainData(setNetworkDetails, networkDetails, setLoading, resetApp);
            await listenAccountChange(setLoading, setNetworkDetails, resetApp);
            await listenNetworkChange(setNetworkDetails, setLoading, resetApp);
        } else {
            console.info(message.web3.extensionError);
            toast.warning(message.web3.extensionError)
        }
    };

    useEffect(() => {
        let injected = localStorage.getItem("injected");
        if (injected && injected !== undefined) {
            let walletName = localStorage.getItem("wallet_name");
            if (walletName && walletName !== undefined) {
                if (walletName === "metamask") {

                    handleConnect();

                }
            }
        }
        // else {
        //     loadBlockChainData(setNetworkDetails, networkDetails, setLoading, resetApp);
        // }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Web3Provider value={{
            loadWeb3,
            loading,
            setLoading,
            networkDetails,
            setNetworkDetails,
            loadBlockChainData,
            listenAccountChange,
            listenNetworkChange,
            handleConnect,
            resetApp,
        }}>
            <div className="App">
                {children}
            </div>
        </Web3Provider>
    </>)
}

export default Providers;
