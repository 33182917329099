import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { labels } from "../../constant/lable";
import { mergeRevealAudio } from "../../services/azure";
import { enviornment } from "../../web3/constants/constants";
import { Web3Context } from "../../web3/contexts/web3Context";
import { poolMethods } from "../../web3/functions/factory";
import Conformation from "../Modal/mergeConformation";
import Success from "../Modal/success";

const MergeAndReveal = (props) => {
  const { date } = props;
  const [getPhaseTwoInstance, setPhaseTwoInstance] = useState();
  const [getPhaseThreeInstance, setPhaseThreeInstance] = useState();
  const [getPhaseForInstance, setPhaseForInstance] = useState();
  const [nftOfGenesis, setNftOfGenesis] = useState([]);
  const [nftOfMusicVial, setNftOfMusicVial] = useState([]);
  const [countDownDate, setCountDownDate] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [showMerge, setShowMerge] = useState(false);
  const [show, setShow] = useState(false);
  const [conformationShow, setConformationShow] = useState(false);
  const { networkDetails, setLoading, loading } = useContext(Web3Context);
  const [mergeVal, setMergeVal] = useState();

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseConformation = () => {
    formik.resetForm();
    setConformationShow(false);
  };

  const setApprovalForAll = async (instance, operator) => {
    let result;
    if (instance) {
      await poolMethods
        .setApprovalForAll(instance, networkDetails.address, operator)
        .then((res) => {
          if (res) {
            result = true;
          }
        })
        .catch((err) => {
          toast.error(err.message);
          result = false;
        });
    }
    return result;
  };

  const getMergeNftList = async () => {
    if (networkDetails && getPhaseTwoInstance && getPhaseThreeInstance) {
      setLoading(true);
      const genesisNftList = await poolMethods.nftOfUser(
        getPhaseTwoInstance,
        networkDetails.address
      );
      setNftOfGenesis(genesisNftList);
      const musicVialNftList = await poolMethods.nftOfUser(
        getPhaseThreeInstance,
        networkDetails.address
      );
      setNftOfMusicVial(musicVialNftList);
      setLoading(false);
    }
  };

  const validateSchema = Yup.object().shape({
    genesisNft: Yup.number().required(labels.meargeAndReveal.formikError),
    musicVial: Yup.number().required(labels.meargeAndReveal.formikError),
  });
  // ** Form Value
  const initialValues = {
    genesisNft: "",
    musicVial: "",
  };

  // ** Formic Form
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validateSchema,
    onSubmit: async (values, { resetForm }) => {
      setMergeVal(values);
      setConformationShow(true);
    },
  });

  useEffect(() => {
    (async () => {
      if (networkDetails && networkDetails.connected) {
        let instanceTwo = await poolMethods.getPhaseTwoInstance(
          networkDetails.web3
        );
        if (instanceTwo) {
          setPhaseTwoInstance(instanceTwo);
        }

        let instanceThree = await poolMethods.getPhaseThreeInstance(
          networkDetails.web3
        );
        if (instanceThree) {
          setPhaseThreeInstance(instanceThree);
        }
        let instanceFor = await poolMethods.getPhaseForInstance(
          networkDetails.web3
        );
        if (instanceFor) {
          setPhaseForInstance(instanceFor);
        }
      }
    })();
  }, [networkDetails]);

  useEffect(() => {
    // Fetch and set merge & reveal  token list
    if (networkDetails && getPhaseTwoInstance && getPhaseThreeInstance) {
      getMergeNftList();
    }
  }, [getPhaseTwoInstance, getPhaseThreeInstance]); // eslint-disable-line

  useEffect(() => {
    // set merge & reveal start time
    if (networkDetails.connected && date.mergeRevealDate !== undefined) {
      let mergeRevealDate = new Date(date?.mergeRevealDate).getTime() * 1000;
      setCountDownDate(mergeRevealDate);
    }
  }, [date, networkDetails, countDown]); // eslint-disable-line

  useEffect(() => {
    if (networkDetails.connected && date?.mergeRevealDate !== undefined) {
      const interval = setInterval(async () => {
        let newtime = new Date(countDownDate).getTime();
        let currDate = new Date().getTime();
        if (currDate > newtime) {
          setShowMerge(true);
        } else {
          setShowMerge(false);
        }
        setCountDown(newtime);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDownDate]); // eslint-disable-line

  const onHandleConformation = async () => {
    try {
      if (
        networkDetails &&
        getPhaseTwoInstance &&
        getPhaseThreeInstance &&
        mergeVal
      ) {
        let envData = enviornment;
        const networkId = await networkDetails.web3.eth.net.getId();
        let isApprovedTwo;
        let isApprovedThree;
        let phaseTwo = await poolMethods.isApprovedForAll(
          getPhaseTwoInstance,
          networkDetails.address,
          envData[networkId].PhaseForAddress
        );
        if (phaseTwo === false) {
          setLoading(true);
          let setTwo = await setApprovalForAll(
            getPhaseTwoInstance,
            envData[networkId].PhaseForAddress
          );
          setLoading(false);
          isApprovedTwo = setTwo;
        } else {
          isApprovedTwo = phaseTwo;
        }
        let phaseThree = await poolMethods.isApprovedForAll(
          getPhaseThreeInstance,
          networkDetails.address,
          envData[networkId].PhaseForAddress
        );
        if (phaseThree === false) {
          setLoading(true);
          let setThree = await setApprovalForAll(
            getPhaseThreeInstance,
            envData[networkId].PhaseForAddress
          );
          setLoading(false);
          isApprovedThree = setThree;
        } else {
          isApprovedThree = phaseThree;
        }
        if (isApprovedTwo === true && isApprovedThree === true) {
          setLoading(true);
          await poolMethods
            .merge(
              getPhaseForInstance,
              networkDetails.address,
              mergeVal.genesisNft,
              mergeVal.musicVial
            )
            .then(async (result) => {
              console.log("mergeRevealAudio", result);
              if (result) {
                let mergeTokenId;
                result.events.Transfer.map((res) => {
                  if (res.returnValues.from === process.env.REACT_APP_REDEEM) {
                    mergeTokenId = res.returnValues.tokenId;
                  }
                  return mergeTokenId;
                });
                let obj = {
                  firstFileToken: mergeVal.genesisNft.toString(),
                  secondFileToken: mergeVal.musicVial.toString(),
                  desinationFileName: mergeTokenId,
                  walletAddress: networkDetails.address,
                };
                await mergeRevealAudio(obj).then((res) => {
                  if (res) {
                    setShow(true);
                    toast.success(labels.meargeAndReveal.success);
                  }
                  getMergeNftList();
                  setConformationShow(false);
                  setLoading(false);
                  formik.resetForm();
                });
              } else {
                setLoading(false);
              }
            })
            .catch((error) => {
              toast.error(error.message);
              setLoading(false);
              setConformationShow(false);
              console.log("error", error);
            });
        }
      }
    } catch (error) {
      console.log("error =", error);
      setConformationShow(false);
      setMergeVal();
      formik.resetForm();
      toast.error("There is some issue with merge.");
    }
  };

  return (
    <>
      <Success
        successMsg={labels.meargeAndReveal.mergeAndRevealSuccess}
        opensea={labels.meargeAndReveal.opensea}
        show={show}
        handleClose={handleClose}
        note={labels.meargeAndReveal.note}
      />
      <Conformation
        alredyMerged={labels.meargeAndReveal.alredyMerged}
        reversable={labels.meargeAndReveal.reversable}
        conformation={labels.meargeAndReveal.conformation}
        remember={labels.meargeAndReveal.remember}
        show={conformationShow}
        handleClose={handleCloseConformation}
        onHandleConformation={onHandleConformation}
      />
      <section className="h-100 bg-black d-flex flex-column justify-content-center py-5">
        {loading && (
          <div className="customLoader">
            <TailSpin type="Rings" color="#fff" height={100} width={100} />
          </div>
        )}
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-sm-10 offset-sm-1">
              <div className="border py-5 p-4 p-sm-5 text-white">
                <div className="text-center mb-5">
                  <h2>Merge & Reveal</h2>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                      <select
                        className="form-select unstyled"
                        value={formik.values.genesisNft}
                        onChange={(e) => {
                          let val = Number(e.target.value);
                          formik.setFieldValue("genesisNft", val);
                        }}
                      >
                        <option value="" className="option" disabled>
                          Select B&0 Genesis NFT
                        </option>
                        {nftOfGenesis.length > 0 &&
                          nftOfGenesis.map((list, idx) => {
                            return (
                              <option className="option" value={list} key={idx}>
                                {list}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {formik.touched.genesisNft && formik.errors.genesisNft ? (
                      <p className="validationMsg">
                        {formik.errors.genesisNft}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-sm-2 d-flex align-items-center justify-content-center my-5 my-sm-0">
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 71 72"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        y1="35.2494"
                        x2="71"
                        y2="35.2494"
                        stroke="#FCFAEE"
                        strokeWidth="1.50126"
                      />
                      <line
                        x1="34.2494"
                        y1="71.5"
                        x2="34.2494"
                        y2="0.5"
                        stroke="#FCFAEE"
                        strokeWidth="1.50126"
                      />
                    </svg>
                  </div>
                  <div className="col-sm-5">
                    <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                      <select
                        className="form-select unstyled"
                        value={formik.values.musicVial}
                        onChange={(e) => {
                          let val = Number(e.target.value);
                          formik.setFieldValue("musicVial", val);
                        }}
                      >
                        <option value="" className="option" disabled>
                          Select B&0 Music Vial
                        </option>
                        {nftOfMusicVial.length > 0 &&
                          nftOfMusicVial.map((list, idx) => {
                            return (
                              <option className="option" value={list} key={idx}>
                                {list}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    {formik.touched.musicVial && formik.errors.musicVial ? (
                      <p className="validationMsg">{formik.errors.musicVial}</p>
                    ) : null}
                    {showMerge === true ? (
                      <button
                        className="btn btn-light btn-lg w-100 mt-5"
                        type="button"
                        // data-start-loader
                        onClick={formik.handleSubmit}
                      >
                        Merge NFTs
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="loader">
        <div className="loader-progress"></div>
        <div className="loader-counter">0</div>
      </div>
    </>
  );
};

export default MergeAndReveal;
