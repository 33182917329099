/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { Web3Context } from "../../web3/contexts/web3Context";
import { Form, Row } from "react-bootstrap";
import { poolMethods } from "../../web3/functions/factory";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { addOrder, getCurrentTime } from '../../services/Order'
import axios from 'axios'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { useNavigate } from "react-router-dom";

const Order = (props) => {
    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [getPhaseThreeInstance, setPhaseThreeInstance] = useState()
    const [getPhaseTwoInstance, setPhaseTwoInstance] = useState()
    const [step, setStep] = useState(1);
    const [phoneCountryCode, setPhoneCountryCode] = useState()
    const navigate = useNavigate();
    const [singleAmount, setSingleAmount] = useState(3333);

    const [userInput, setUserInput] = useState({
        firstName: '',
        lastName: '',
        email: '',
        paymentType: "",
        transactionAddress: '',
        address: '',
        city: '',
        country: '',
        phone: '',
        artWork: '',
        speakersQuantity: '1',
        engravingText: '',
        amount: singleAmount
    });
    const paymentTypeList = [
        { key: "ETH", value: "ETH" },
        { key: "Fiat", value: "Fiat" }
    ]

    const countriesList = [
        { key: "United Kingdom", value: "United Kingdom" },
        { key: "Japan", value: "Japan" },
        { key: "China", value: "China" },
        { key: "Honk Kong", value: "Honk Kong" },
        { key: "United States", value: "United States" },
        { key: "Denmark", value: "Denmark" },
        { key: "Germany", value: "Germany" },
        { key: "Austria", value: "Austria" },
        { key: "Switzerland", value: "Switzerland" },
        { key: "Netherlands", value: "Netherlands" },
        { key: "Belgium", value: "Belgium" },
        { key: "France", value: "France" },
        { key: "Spain", value: "Spain" },
        { key: "Italy", value: "Italy" },
        { key: "Portugal", value: "Portugal" },
        { key: "UK", value: "UK" },
        { key: "Ireland", value: "Ireland" },
        { key: "Sweden", value: "Sweden" },
        { key: "Finland", value: "Finland" },
        { key: "Norway", value: "Norway" }
    ]

    const artWorkList = [
        { key: "Hackatao", value: "Hackatao" },
        { key: "Shavonne Wong", value: "Shavonne Wong" },
        { key: "Thomas Lin Pedersen", value: "Thomas Lin Pedersen" }
    ]

    const fetchIpLocation = () => {
        ; (async () => {
            await axios.get(`https://ipapi.co/json/`).then((response) => {
                setPhoneCountryCode(response.data.country_code)
            })
        })()
    }

    useEffect(() => {
        fetchIpLocation()
    }, [])

    useEffect(() => {
        let amount = userInput.amount
        if (formik.values.speakersQuantity == 1) {
            amount = singleAmount * 1
        } else if (formik.values.speakersQuantity == 2) {
            amount = singleAmount * 2
        } else if (formik.values.speakersQuantity == 3) {
            amount = singleAmount * 3
        }
        formik.setFieldValue('amount', amount)
        setUserInput((prevState) => {
            return { ...prevState, amount };
        });
    }, [userInput.speakersQuantity])

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instanceTwo = await poolMethods.getPhaseTwoInstance(networkDetails.web3)
                if (instanceTwo) {
                    setPhaseTwoInstance(instanceTwo)
                }

                let instancePhase4 = await poolMethods.getPhaseForInstance(networkDetails.web3)
                if (instancePhase4) {
                    setPhaseThreeInstance(instancePhase4)
                }

                let listPhase2 = [], listPhase4 = []
                if (instanceTwo || instancePhase4) {
                    listPhase2 = await poolMethods.nftOfUser(instanceTwo, networkDetails.address)
                    listPhase4 = await poolMethods.nftOfUser(instancePhase4, networkDetails.address)
                }
                if (!(listPhase2 && listPhase2.length) && !(listPhase4 && listPhase4.length)) {
                    navigate('/')
                }
            }
        })();
    }, [networkDetails])

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    const transactionAddressRegExp = /^(0x)?[0-9a-fA-F]{40}$/

    const validateSchema1 = Yup.object().shape({
        firstName: Yup.string().trim()
            .required("Enter name"),
        lastName: Yup.string().trim()
            .required("Enter last name"),
        email: Yup.string().trim()
            .required("Enter email")
            .matches(regexEmail, 'Enter valid email'),
        paymentType: Yup.string().trim()
            .required("Select payment type"),
        transactionAddress: Yup.string()
            .when("paymentType", {
                is: 'ETH',
                then: Yup.string().trim().required("Enter transaction wallet address").matches(transactionAddressRegExp, 'Enter valid wallet address'),
            })
    })
    const [validateSchema, setValidateSchema] = useState(validateSchema1)

    const validateSchema2 = Yup.object().shape({
        address: Yup.string().trim()
            .required("Enter address"),
        city: Yup.string().trim(),
        country: Yup.string().trim()
            .required("Select country"),
        phone: Yup.string()
            .test('isValidPhoneNumber', 'Invalid phone number', (value) =>
                value ? isValidPhoneNumber(value) : true)
    })

    const validateSchema3 = Yup.object().shape({
        artWork: Yup.string().trim()
            .required("Select artwork"),
        speakersQuantity: Yup.number().min(1, 'Minimum 1')
            .required("Select speakers quantity"),
        engravingText: Yup.string().trim(),
        amount: Yup.string().trim()
    })

    const handleNextStep = () => {
        if (step === 1) {
            formik.setFieldTouched('address', false);
            formik.setFieldTouched('city', false);
            formik.setFieldTouched('country', false);
            formik.setFieldTouched('phone', false);
            setValidateSchema(validateSchema2)
        } else if (step === 2) {
            formik.setFieldTouched('artWork', false);
            formik.setFieldTouched('speakersQuantity', false);
            formik.setFieldTouched('engravingText', false);
            setValidateSchema(validateSchema3)
        }
        setStep(step + 1);
    };

    // ** Form Value
    const initialValues = {
        firstName: "",
        lastName: "",
        email: "",
        paymentType: "",
        transactionAddress: '',
        address: "",
        city: "",
        country: "",
        phone: "",
        artWork: "",
        speakersQuantity: '1',
        engravingText: '',
        amount: singleAmount
    }

    // ** Formic Form 
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {
            if (step !== 3) {
                handleNextStep()
            } else {
                let listPhase2 = "", listPhase3 = ""
                if (getPhaseTwoInstance && getPhaseThreeInstance && networkDetails) {
                    listPhase2 = await poolMethods.nftOfUser(getPhaseTwoInstance, networkDetails.address)
                    listPhase3 = await poolMethods.nftOfUser(getPhaseThreeInstance, networkDetails.address)
                }
                values = {
                    ...values, walletAddress: networkDetails.address, listPhase2Nft: listPhase2.toString(),
                    listPhase3Nft: listPhase3.toString()
                }
                setLoading(true)
                const result = await addOrder(values)
                setLoading(false)
                toast.success(result.message)
                resetForm()
                formik.setFieldValue('speakersQuantity', '1')
                setUserInput(
                    {
                        firstName: '',
                        lastName: '',
                        email: '',
                        paymentType: "",
                        transactionAddress: '',
                        address: '',
                        city: '',
                        country: '',
                        phone: '',
                        artWork: '',
                        speakersQuantity: '1',
                        engravingText: '',
                        amount: singleAmount
                    })
                setStep(1)
                setValidateSchema(validateSchema1)
                setTimeout(() => navigate('/'), 1000)
            }
        }
    });

    useEffect(() => {
        (async () => {
            const result = await getCurrentTime()
            const milisecondsTime = result.data;
            const dateToBeCompared = 1699484460000;
            if (milisecondsTime > dateToBeCompared) {
                setSingleAmount(3969)
                formik.setFieldValue('amount', 3969)
                setUserInput((prevState) => {
                    return { ...prevState, amount: 3969 };
                });
            } else {
                setSingleAmount(3333)
                formik.setFieldValue('amount', 3333)
                setUserInput((prevState) => {
                    return { ...prevState, amount: 3333 };
                });
            }
        })()
    }, [])

    const inputChangeHandler = (event) => {
        setUserInput((prevState) => {
            return { ...prevState, [event.target.name]: event.target.value };
        });
        formik.handleChange(event.target.name)(event)
    }

    const onHandlePlus = () => {
        const value = Number(userInput.speakersQuantity)
        if (value <= 2 && value >= 0) {
            formik.setFieldValue('speakersQuantity', value + 1)
            setUserInput((prevState) => {
                return { ...prevState, speakersQuantity: value + 1 };
            });
        }
    }
    const onHandleMinus = () => {
        const value = Number(userInput.speakersQuantity)
        if (value <= 3 && value >= 2) {
            formik.setFieldValue('speakersQuantity', value - 1)
            setUserInput((prevState) => {
                return { ...prevState, speakersQuantity: value - 1 };
            });
        }
    }

    return (
        <>
            <section className="h-100 d-flex flex-column justify-content-center py-5">
                {loading && (
                    <div className="customLoader">
                        <TailSpin type="Rings" color="#fff" height={100} width={100} />
                    </div>
                )}
                <div className="container-fluid custom_page_style">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12 mt-5 mt-xl-0 mx-md-auto me-lg-auto">
                            <div className="border py-7 p-4 p-sm-5 text-white mt-5 pt-5">
                                <div className="text-center">
                                    <h2>Place Order</h2>
                                </div>
                                <div className="mb-4 mb-sm-5 pageNumber">
                                    <h5>{step}/3</h5>
                                </div>
                                {
                                    step === 1 &&
                                    (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3">
                                                            <label className="form-label-order">First name *</label>
                                                            <div className="d-flex justify-content-between mb-2">
                                                                <input className="form-control w-100 text-start text-white small-font" type="text" name="firstName"
                                                                    autoComplete="off"
                                                                    onChange={inputChangeHandler}
                                                                    value={formik.values.firstName}
                                                                />
                                                            </div>
                                                        </div>
                                                        {formik.touched.firstName && formik.errors.firstName ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.firstName}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3 ">
                                                            <label className="form-label-order">Last name *</label>
                                                            <div className="d-flex justify-content-between mb-2">
                                                                <input className="form-control w-100 text-start text-white small-font" type="text" name="lastName"
                                                                    autoComplete="off"
                                                                    onChange={inputChangeHandler}
                                                                    value={formik.values.lastName}
                                                                />
                                                            </div>
                                                        </div>
                                                        {formik.touched.lastName && formik.errors.lastName ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.lastName}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="input-border mb-3 ">
                                                    <label className="form-label-order">Email *</label>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <input className="form-control w-100 text-start text-white small-font" type="email" name="email"
                                                            autoComplete="off"
                                                            onChange={inputChangeHandler}
                                                            value={formik.values.email}
                                                        />
                                                    </div>
                                                </div>
                                                {formik.touched.email && formik.errors.email ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.email}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div>
                                                <div className="input-border mb-3 ">
                                                    <label className="form-label-order">Payment Type *</label>
                                                    <select className="form-select unstyled text-white small-font"
                                                        value={formik.values.paymentType}
                                                        onChange={(e) => {
                                                            if (e.target.value) {
                                                                formik.handleChange("paymentType")(e)
                                                                setUserInput((prevState) => {
                                                                    return { ...prevState, paymentType: e.target.value, address: "" };
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <option value={""} disabled hidden className="option"></option>
                                                        {paymentTypeList.length > 0 && paymentTypeList.map((data, idx) => {
                                                            return <option value={data.key} className="option" key={idx}>{data.value}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {formik.touched.paymentType && formik.errors.paymentType ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.paymentType}
                                                    </p>
                                                ) : null}
                                            </div>

                                            {
                                                formik.values.paymentType === 'ETH' ?
                                                    (
                                                        <div>
                                                            <div className="input-border mb-3 ">
                                                                <label className="form-label-order">Transaction Wallet Address *</label>
                                                                <div className="d-flex justify-content-between mb-2">
                                                                    <input className="form-control w-100 text-start text-white small-font" type="text" name="transactionAddress"
                                                                        autoComplete="off"
                                                                        onChange={inputChangeHandler}
                                                                        value={formik.values.transactionAddress}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {formik.touched.transactionAddress && formik.errors.transactionAddress ? (
                                                                <p className='validationMsgOrder'>
                                                                    {formik.errors.transactionAddress}
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    )
                                                    : null
                                            }

                                            {
                                                networkDetails && networkDetails.connected &&
                                                <Row className="justify-content-center">
                                                    <button
                                                        className="btn btn-light btn-lg mt-5 text-uppercase btn-order"
                                                        data-start-loader
                                                        type="button"
                                                        onClick={formik.handleSubmit}
                                                    >
                                                        NEXT
                                                    </button>
                                                </Row>
                                            }
                                        </Form>
                                    )
                                }

                                {
                                    step === 2 &&
                                    (
                                        <Form>
                                            <div>
                                                <div className="input-border mb-3 ">
                                                    <label className="form-label-order">Address *</label>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <input className="form-control w-100 text-start text-white small-font" name="address"
                                                            autoComplete="off"
                                                            onChange={inputChangeHandler}
                                                            value={formik.values.address}
                                                        />
                                                    </div>
                                                </div>
                                                {formik.touched.address && formik.errors.address ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.address}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3 ">
                                                            <label className="form-label-order">City</label>
                                                            <div className="d-flex justify-content-between mb-2">
                                                                <input className="form-control w-100 text-start text-white small-font" name="city"
                                                                    autoComplete="off"
                                                                    onChange={inputChangeHandler}
                                                                    value={formik.values.city}
                                                                />
                                                            </div>
                                                        </div>
                                                        {formik.touched.city && formik.errors.city ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.city}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3 ">
                                                            <label className="form-label-order">Country *</label>
                                                            <select className="form-select unstyled text-white small-font"
                                                                value={formik.values.country}
                                                                onChange={(e) => {
                                                                    if (e.target.value) {
                                                                        formik.handleChange("country")(e)
                                                                        setUserInput((prevState) => {
                                                                            return { ...prevState, country: e.target.value };
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <option value={""} disabled hidden className="option"></option>
                                                                {countriesList.length > 0 && countriesList.map((data, idx) => {
                                                                    return <option value={data.key} className="option" key={idx}>{data.value}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                        {formik.touched.country && formik.errors.country ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.country}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <div className="input-border  mb-3 ">
                                                    <label className="form-label-order">Phone Number *</label>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <PhoneInput
                                                            onChange={(phone) => {
                                                                formik.setFieldValue('phone', phone)
                                                            }}
                                                            autoComplete="off"
                                                            value={formik.values.phone}
                                                            defaultCountry={phoneCountryCode}
                                                            id="phoneInput"
                                                            name="phone"
                                                            className="form-control w-100 text-start text-white small-font"
                                                        />
                                                    </div>
                                                </div>
                                                {formik.touched.phone && formik.errors.phone ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.phone}
                                                    </p>
                                                ) : null}
                                            </div>
                                            {
                                                networkDetails && networkDetails.connected &&
                                                <Row className="justify-content-center">
                                                    <button
                                                        className="btn btn-light btn-lg btn-order mt-5 text-uppercase"
                                                        data-start-loader
                                                        type="button"
                                                        onClick={formik.handleSubmit}
                                                    >
                                                        NEXT
                                                    </button>
                                                </Row>
                                            }
                                        </Form>
                                    )
                                }

                                {
                                    step === 3 &&
                                    (
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3">
                                                            <label className="form-label-order">Artwork *</label>
                                                            <select className="form-select unstyled text-white small-font"
                                                                value={formik.values.artWork}
                                                                onChange={(e) => {
                                                                    if (e.target.value) {
                                                                        formik.handleChange("artWork")(e)
                                                                        setUserInput((prevState) => {
                                                                            return { ...prevState, artWork: e.target.value };
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <option value={""} disabled hidden className="option"></option>
                                                                {artWorkList.length > 0 && artWorkList.map((data, idx) => {
                                                                    return <option value={data.key} className="option" key={idx}>{data.value}</option>
                                                                })}
                                                            </select>
                                                        </div>
                                                        {formik.touched.artWork && formik.errors.artWork ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.artWork}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div>
                                                        <div className="input-border mb-3">
                                                            <label className="form-label-order">Number of Speakers *</label>
                                                            <div className="quantity flex justify-content-end" style={{ marginTop: "9px" }}>
                                                                <span className="change text-white small-font" data-qty="minus" onClick={onHandleMinus}>—</span>
                                                                <input min="1" className="form-control text-white small-font" type="number" id="quantity" name="speakersQuantity"
                                                                    value={formik.values.speakersQuantity}
                                                                    onChange={(e) => {
                                                                        if (Number(e.target.value) <= 3 && Number(e.target.value) >= 0) {
                                                                            formik.handleChange('speakersQuantity')(e)
                                                                            setUserInput((prevState) => {
                                                                                return { ...prevState, speakersQuantity: e.target.value };
                                                                            });
                                                                        } else {
                                                                            formik.setFieldValue('speakersQuantity', 1)
                                                                            setUserInput((prevState) => {
                                                                                return { ...prevState, speakersQuantity: 1 };
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                                <span className="change text-white small-font" onClick={onHandlePlus}>+</span>
                                                            </div>
                                                        </div>
                                                        {formik.touched.speakersQuantity && formik.errors.speakersQuantity ? (
                                                            <p className='validationMsgOrder'>
                                                                {formik.errors.speakersQuantity}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="input-border mb-3 ">
                                                    <label className="form-label-order">Engraving Text</label>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <input className="form-control w-100 text-start text-white small-font" type="text" name="engravingText"
                                                            autoComplete="off"
                                                            onChange={inputChangeHandler}
                                                            value={formik.values.engravingText}
                                                        />
                                                    </div>
                                                </div>
                                                {formik.touched.engravingText && formik.errors.engravingText ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.engravingText}
                                                    </p>
                                                ) : null}
                                            </div>
                                            <div>
                                                <div className="input-border mb-3 ">
                                                    <label className="form-label-order">Total</label>
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <input className="form-control w-100 text-start text-white small-font" type="text" name="amount"
                                                            autoComplete="off"
                                                            onChange={inputChangeHandler}
                                                            value={`${formik.values.amount} USD`}
                                                        />
                                                    </div>
                                                </div>
                                                {formik.touched.amount && formik.errors.amount ? (
                                                    <p className='validationMsgOrder'>
                                                        {formik.errors.amount}
                                                    </p>
                                                ) : null}
                                            </div>
                                            {
                                                networkDetails && networkDetails.connected &&
                                                <Row className="justify-content-center">
                                                    <button
                                                        className="btn btn-light btn-lg btn-order mt-5 text-uppercase"
                                                        data-start-loader
                                                        type="button"
                                                        onClick={formik.handleSubmit}
                                                    >
                                                        DONE
                                                    </button>
                                                </Row>
                                            }
                                        </Form>
                                    )
                                }
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 px-5 flex align-items-center justify-content-center mt-4 mt-md-5 mt-lg-0">
                            {
                                userInput.artWork === "Hackatao" ?
                                    <img className="artwork_image" src="/img/Beoplay_A9_Hackatao_R01_0000.png" alt="" /> :
                                    formik.values.artWork === "Shavonne Wong" ?
                                        <img className="artwork_image" src="/img/Beoplay_A9_Shavonne_R01_0000.png" alt="" /> :
                                        <img className="artwork_image" src="/img/Beoplay_A9__Lin_R05_0000.png" alt="" />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Order;
