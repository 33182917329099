import React from "react";

const Asseenon = () => {
  return (
    <>
      <section className="py-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <h2 data-aos="fade-in">As seen on</h2>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-4 p-5 text-center">
              <a
                href="https://www.highsnobiety.com/p/bang-and-olufsen-nft-collection"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/Highsnobiety.png"
                  data-aos="fade-up"
                  width="300px"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-4 p-5 text-center">
              <a
                href="https://www.trendhunter.com/trends/bang-olufsen-the-dna-collection"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/Trendhunter.png"
                  data-aos="fade-up"
                  width="300px"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-4 p-5 text-center">
              <a
                href="https://www.luxuryabode.com/blog/bang---olufsen-of-denmark-introduces-its-first-nft-drop-the-dna-collection/artid1942"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/LuxuryAbode.png"
                  data-aos="fade-up"
                  width="300px"
                  alt=""
                />
              </a>
            </div>
            <div className="col-sm-4 offset-sm-4 p-5 text-center">
              <a
                href="https://luxurylaunches.com/gadgets/bang-olufsen-has-entered-the-metaverse-with-its-first-nft-drop-called-the-dna-collection.php"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="img/LuxuryLaunches.png"
                  data-aos="fade-up"
                  width="300px"
                  alt=""
                />
              </a>
            </div>
            {/* <div className="col-sm-4 p-5 text-center">
                        <a href="https://www.yankodesign.com/2022/11/02/bang-olufsen-brings-its-design-dna-to-the-metaverse-with-its-first-nfts/" target="_blank" rel="noreferrer">
                            <img src="img/yk.png" data-aos="fade-up" width="300px" alt="" />
                        </a>
                    </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Asseenon;
