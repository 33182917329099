import axios from "axios";

// ** Add Wallet Address
export const moveAzureFile = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/azure`,
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        data: formData,
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const moveAzureFiles = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/azureFile`,
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        data: formData,
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const mergeReveal = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/mergeReveal`,
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        data: formData,
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const mergeRevealAudio = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/mergeAudioImage`,
        method: "post",
        headers: {
            "Content-Type": "application/json"
        },
        data: formData,
    }

    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const generateThreeDModel = async (formData) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/generateThreeDModel/${formData.tokenId}/${formData.withArt}`,
        method: "get",
        headers: {
            "Content-Type": "application/json"
        },
    }

    return await axios(axiosData).then(async (result) => {
        console.log("3d response", result.data)
        return result.data;
    }).catch(async (error) => {
        console.log("3d error", error)
        return error.response.data;
    })
}