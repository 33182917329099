
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { labels } from "../../constant/lable";
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';

const ArtVialCheck = () => {
    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [userWtMessage, setUserWtMessage] = useState()
    const [getPhaseOneInstance, setPhaseOneInstance] = useState()

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instancePhaseOne = await poolMethods.getPhaseOneInstance(networkDetails.web3)
                if (instancePhaseOne) {
                    setPhaseOneInstance(instancePhaseOne)
                }
            }
        })();
    }, [networkDetails])


    const validateSchema = Yup.object().shape({
        artVialCheck: Yup.string()
            .required("Please enter number")
            .matches(/^[0-9]+$/i, "Allowed only number for this field "),
    })

    // ** Form Value
    const initialValues = {
        artVialCheck: "",
    }

    // ** Formic Form 
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {
            const details = values.artVialCheck
            if (networkDetails && networkDetails.connected && getPhaseOneInstance) {
                try {
                    setLoading(true)
                    const checkcliamed = await poolMethods.alreadyClaimed(getPhaseOneInstance, networkDetails.address, details)
                    setLoading(false)
                    setUserWtMessage(checkcliamed)
                } catch (err) {
                    console.log('come to error part = ', err)
                    setLoading(false)
                }
            } else {
                toast.error(labels.artVialCheck.artVialCheckError)
            }
        },
    });

    return (
        <>
            <section>
                {loading &&
                    <div className="customLoader">
                        <TailSpin
                            type="Rings"
                            color="#fff"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div>
                            <div className="border py-5 p-4 p-sm-5 text-white cheight">
                                <div className="text-center mb-5">
                                    <h2>{labels.artVialCheck.artVialCheck}</h2>
                                </div>
                                <div className='whitelist-body'>
                                    <Form>
                                        <div className="input-border mb-0 ">
                                            <label>
                                                {labels.artVialCheck.artVialCheckList}
                                            </label>
                                            <div className="d-flex justify-content-between mt-3 mb-2">
                                                <input className="whitelistInput" type="text" name={labels.artVialCheck.artVialCheckList}
                                                    autoComplete="off"
                                                    onChange={async (e) => {
                                                        setUserWtMessage()
                                                        formik.handleChange('artVialCheck')(e)
                                                    }}
                                                    value={formik.values.artVialCheck}
                                                />
                                            </div>
                                        </div>
                                        {formik.touched.artVialCheck && formik.errors.artVialCheck ? (
                                            <p className='validationMsg'>
                                                {formik.errors.artVialCheck}
                                            </p>
                                        ) :
                                            null
                                        }
                                        {userWtMessage !== undefined ? userWtMessage === true ?
                                            <p className="validationMsg">
                                                {labels.artVialCheck.artViallisted + ' ' + formik.values.artVialCheck}
                                            </p>
                                            :
                                            <p>
                                                {labels.artVialCheck.artVialCheckedMsg + ' ' + formik.values.artVialCheck}</p>
                                            :
                                            formik.errors.artVialCheck ?
                                                ""
                                                :
                                                <p className='validationMsg mb-10'></p>
                                        }
                                    </Form>
                                </div>
                                <button className="mint-btn btn btn-light btn-lg w-100 mt-5" type="button" onClick={formik.handleSubmit}>{labels.artVialCheck.check}</button>
                            </div>
                            <div className="mt-34 text-white small text-center">
                                {/* Each B&O DNA NFT is unique and created by B&O */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ArtVialCheck