import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { TailSpin } from 'react-loader-spinner';
import * as Yup from 'yup';
import { labels } from '../../constant/lable';
import { moveAzureFiles } from '../../services/azure';
import { enviornment } from '../../web3/constants/constants';
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';
import Success from '../Modal/success';
const Redeem = (props) => {
    const { date } = props
    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [getInstance, setInstance] = useState()
    const [getPhase0Instance, setPhase0Instance] = useState()
    const [mintList, setMintList] = useState([])
    const [countDownDate, setCountDownDate] = useState(null);
    const [countDown, setCountDown] = useState(0);
    const [showRedeem, setShowRedeem] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instance = await poolMethods.getInstance(networkDetails.web3)
                if (instance) {
                    setInstance(instance)
                }
                const Phase0Instance = await poolMethods.getPhase0Instance(networkDetails.web3)
                if (Phase0Instance) {
                    setPhase0Instance(Phase0Instance)
                }
            }
        })();
    }, [networkDetails])

    useEffect(() => {
        ; (async () => { // set redeem start time
            if (networkDetails.connected && date.redeemDate !== undefined) {
                let redeemDate = new Date(date?.redeemDate).getTime() * 1000;
                setCountDownDate(redeemDate)

            }

        })();
    }, [date, networkDetails, countDown]) // eslint-disable-line

    useEffect(() => {
        if (networkDetails.connected) {
            const interval = setInterval(async () => {
                let newtime = new Date(countDownDate).getTime()
                let currDate = new Date().getTime()
                if (currDate > newtime) {
                    setShowRedeem(true)
                } else {
                    setShowRedeem(false)
                }
                setCountDown(newtime);
            }, 1000);
            return () => clearInterval(interval);
        }

    }, [countDownDate]); // eslint-disable-line

    const reddemList = async () => { // fetch & set redeem list
        if (getInstance && networkDetails) {
            const list = await poolMethods.nftOfUser(getInstance, networkDetails.address)
            formik.resetForm()
            setMintList(list)
        }
    }

    const redeem = async (val) => {
        setLoading(true)
        await poolMethods.redeem(getPhase0Instance, networkDetails.address, val).then(async (data) => {
            if (data) {
                let tokenId;
                let data0 = data.events.Transfer[0].returnValues
                let dataOne = data.events.Transfer[1].returnValues
                if (data0.from === process.env.REACT_APP_REDEEM) {
                    tokenId = data0.tokenId
                } else {
                    tokenId = dataOne.tokenId
                }
                let obj = {
                    fileName: tokenId.toString(),
                    phase: "redeem"
                }
                await moveAzureFiles(obj).then((result) => {
                    if (result) {
                        reddemList()
                        setShow(true)
                        formik.resetForm()
                    }
                }).catch(errors => {
                    reddemList()
                    formik.resetForm()
                })

            }
        }).catch(error => {
            setLoading(false)
        })
        setLoading(false)
    }

    const validateSchema = Yup.object().shape({
        redeem: Yup.number()
            .required("Please enter token-id.")
    })
    // ** Form Value
    const initialValues = {
        redeem: "",
    }

    const redeemSeo = (() => {
        if (window?.dataLayer) {
            window.dataLayer.push(labels.redeemNow)
        }
    })

    // ** Formic Form
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {

            if (networkDetails && getInstance && getPhase0Instance && values.redeem >= 0) {
                redeemSeo()
                setLoading(true)
                let envData = enviornment;
                const networkId = await networkDetails.web3.eth.net.getId();
                await poolMethods.isApprovedForAll(getInstance, networkDetails.address, envData[networkId].Phase0Address).then(async data => {
                    if (data === true) {
                        await redeem(values.redeem)
                    } else {
                        await poolMethods.setApprovalForAll(getInstance, networkDetails.address, envData[networkId].Phase0Address).then(async result => {
                            if (result) {
                                await redeem(values.redeem)
                            }
                        }).catch(error => {
                            resetForm()
                            setLoading(false)
                        })
                    }
                }).catch(err => {
                    resetForm()
                    setLoading(false)
                })
            }
        },
    });


    useEffect(() => {
        ; (async () => {
            if (getInstance && networkDetails) {
                reddemList()
            }
        })();
    }, [getInstance])// eslint-disable-line

    return <>
        < Success successMsg={labels.redeem.redeemSuccess} opensea={labels.redeem.opensea} show={show} handleClose={handleClose} />
        <section className="h-100 bg-black d-flex flex-column justify-content-center py-5">

            {loading &&
                <div className="customLoader">
                    <TailSpin  // spin loader
                        type="Rings"
                        color="#fff"
                        height={100}
                        width={100}
                    />
                </div>
            }
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-sm-5">
                        <div className="border py-5 p-4 p-sm-5 text-white">
                            <div className="text-center mb-5">
                                <h2>Redeem NFT</h2>
                            </div>
                            <div className="d-flex justify-content-between align-items-center border-bottom py-4">
                                <select className="form-select unstyled"
                                    value={formik.values.redeem}
                                    onChange={(e) => {
                                        if (e.target.value !== "Select Token ID") {
                                            let val = Number(e.target.value)
                                            formik.setFieldValue('redeem', val)
                                        }
                                    }}
                                >
                                    <option value={""} disabled className="option">Select Token ID</option>
                                    {mintList.length > 0 && mintList.map((data, idx) => {
                                        return <option value={data} className="option" key={idx}>{data}</option>
                                    })}
                                </select>
                            </div>
                            {formik.touched.redeem && formik.errors.redeem ? (
                                <p className='validationMsg'>
                                    {formik.errors.redeem}
                                </p>
                            ) : null}
                            {showRedeem === true ?
                                <button className="btn btn-light btn-lg w-100 mt-5" type="submit" onClick={formik.handleSubmit}
                                // data-start-loader
                                >
                                    Redeem now
                                </button>
                                : ""}
                        </div>
                        <div className="mt-3 text-white small text-center">
                            Each B&O DNA NFT is unique and created by B&O
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="loader">
            <div className="loader-progress"></div>
            <div className="loader-counter">0</div>
        </div>
    </>
}

export default Redeem;