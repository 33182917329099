
import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { TailSpin } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { labels } from "../../constant/lable";
import { Web3Context } from '../../web3/contexts/web3Context';
import { poolMethods } from '../../web3/functions/factory';

const Whitelist = () => {
    const { networkDetails, loading, setLoading } = useContext(Web3Context);
    const [getInstance, setInstance] = useState()
    const [userWtMessage, setUserWtMessage] = useState()

    useEffect(() => {
        ; (async () => {
            if (networkDetails && networkDetails.connected) {
                let instance = await poolMethods.getInstance(networkDetails.web3)
                if (instance) {
                    setInstance(instance)
                }
            }
        })();
    }, [networkDetails])


    const validateSchema = Yup.object().shape({
        whitelistUser: Yup.string()
            .required("Please enter walletaddress")
            .matches(/^[a-z0-9]+$/i, "Only alphanumeric value are allowed for this field "),
    })



    // ** Form Value
    const initialValues = {
        whitelistUser: "",
    }

    // ** Formic Form 
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validateSchema,
        onSubmit: async (values, { resetForm }) => {
            const details = values.whitelistUser
            if (networkDetails && networkDetails.connected && getInstance) {
                try {
                    setLoading(true)
                    const checkWhitelist = await poolMethods.whitelistedAddresses(getInstance, networkDetails.address, details)
                    setLoading(false)
                    setUserWtMessage(checkWhitelist)
                } catch (err) {
                    setLoading(false)
                }
            } else {
                toast.error(labels.whitelist.wallateError)
            }
        },
    });

    return (
        <>
            <section className="h-100 bg-black d-flex flex-column justify-content-center py-5">
                {loading &&
                    <div className="customLoader">
                        <TailSpin
                            type="Rings"
                            color="#fff"
                            height={100}
                            width={100}
                        />
                    </div>
                }
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <div className="border py-5 p-4 p-sm-5 text-white">
                                <div className="text-center mb-5">
                                    <h2>{labels.whitelist.whitelist}</h2>
                                </div>
                                <div className='whitelist-body'>
                                    <Form>
                                        <div className="input-border mb-0 ">
                                            <label>
                                                {labels.whitelist.checkWhitelist}
                                            </label>
                                            <div className="d-flex justify-content-between mb-2">
                                                <input className="whitelistInput" type="text" name={labels.whitelist.checkWhitelist}
                                                    autoComplete="off"
                                                    onChange={async (e) => {
                                                        setUserWtMessage()
                                                        formik.handleChange('whitelistUser')(e)
                                                    }}
                                                    value={formik.values.whitelistUser}
                                                />
                                            </div>
                                        </div>
                                        {formik.touched.whitelistUser && formik.errors.whitelistUser ? (
                                            <p className='validationMsg'>
                                                {formik.errors.whitelistUser}
                                            </p>
                                        ) : null}
                                        {userWtMessage !== undefined ? userWtMessage === true ?
                                            <p>{labels.whitelist.whitelisted}</p>
                                            : <p className="validationMsg">{labels.whitelist.whiteListedMsg}</p> : ""}
                                    </Form>
                                </div>
                                <button className="mint-btn btn btn-light btn-lg w-100 mt-5" type="button" onClick={formik.handleSubmit}>{labels.whitelist.check}</button>
                            </div>
                            <div className="mt-3 text-white small text-center">
                                Each B&O DNA NFT is unique and created by B&O
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="loader">
                <div className="loader-progress"></div>
                <div className="loader-counter">0</div>
            </div>
        </>
        // </div>

    )
}

export default Whitelist