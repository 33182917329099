import MusicVialCheck from "../musicVialCheck";
import MusicVialClaim from "./musicVialClaim";

const MusicVialIndex = ()=>{
    return (
        <>
          <div className="claimIndex d-flex h-100 bg-black justify-content-center">
            <div className="claimArt">
              <MusicVialClaim />
            </div>
            <div className="claimArtCheck">
              <MusicVialCheck />
            </div>
          </div>
        </>
      );
}

export default MusicVialIndex