import axios from "axios";

export const addOrder = async (data) => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/orders`,
        method: "post",
        headers: { "Content-Type": "application/json" },
        data
    }
    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}

export const getCurrentTime = async () => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/getTime`,
        method: "get",
        headers: { "Content-Type": "application/json" },
    }
    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}