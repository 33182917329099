import axios from "axios";

export const getLinks = async () => {
    const axiosData = {
        url: `${process.env.REACT_APP_HOST}/settings`,
        method: "get",
        headers: { "Content-Type": "application/json" },
    }
    return await axios(axiosData).then(async (result) => {
        return result.data;
    }).catch(async (error) => {
        return error.response.data;
    })
}